import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, map, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from './models/api-response';
import { SessionData } from './models/session-data';
import { LoginResponse } from './models/login-response';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private baseUrl: string = environment.apiUrl;
	public session: SessionData | null = null;

	/**
	 * Construtor que inicializa o HttpClient e o Router, além de carregar a sessão atual do localStorage.
	 * @param http HttpClient para fazer requisições HTTP.
	 * @param router Router para navegação entre rotas.
	 */
	constructor(private http: HttpClient, private router: Router) {
		this.loadSession();
	}

	/**
	 * Carrega a sessão do usuário do localStorage, se existir.
	 */
	private loadSession(): void {
		const sessionString = localStorage.getItem('session');
		if (sessionString) {
			this.session = JSON.parse(sessionString);
		}
	}

	/**
	 * Realiza o login do usuário.
	 * @param cnpj O CNPJ do usuário para login.
	 * @param password A senha do usuário para login.
	 * @returns Um Observable com a resposta do login.
	 */
	// Adicionando um parâmetro adicional "loginType" para indicar o tipo de login
	login(identifier: string, password: string, loginType: 'CNPJ' | 'CPF'): Observable<LoginResponse> {
		console.log('login authservice', identifier, password, loginType);
		const loginUrl = `${this.baseUrl}/login`;

		// Preparando o payload com base no tipo de login
		const payload = loginType === 'CNPJ' ? { cnpj: identifier, password } : { cpf: identifier, password };

		return this.http.post<ApiResponse<LoginResponse>>(loginUrl, payload).pipe(
			map((response) => response.data),
			tap((data) => {
				console.log('login: ', data);
				this.session = { token: data.token, user: data.data, role: data.role };
				localStorage.setItem('session', JSON.stringify(this.session));
			}),
			catchError((error) => {
				console.error('Login Error:', error);
				return throwError(() => new Error('Login failed'));
			})
		);
	}

	/**
	 * Realiza o logout do usuário, limpando a sessão do localStorage e redirecionando para a página de login.
	 * @returns Um Observable que completa após a operação de logout.
	 */
	logout(): Observable<void> {
		console.log('logout: ', this.session);
		if (!this.session) return throwError(() => new Error('No session found'));

		const headers = new HttpHeaders({ Authorization: `Bearer ${this.session.token}` });
		this.session = null;
		localStorage.removeItem('session');
		console.log('headers: ', headers);

		return this.http.post<void>(`${this.baseUrl}/logout`, {}, { headers }).pipe(
			tap(() => {
				this.router.navigateByUrl('/');
			}),
			catchError((error) => {
				console.error('Logout Error:', error);
				return throwError(() => new Error('Logout failed'));
			})
		);
	}

	/**
	 * Obtém o ID da empresa da sessão atual, se disponível.
	 * @returns O ID da empresa ou undefined se não disponível.
	 */
	getEmpresaId(): number | undefined {
		return this.session?.user.id;
	}

	/**
	 * Obtém o token da sessão atual, se disponível.
	 * @returns O token da sessão ou undefined se não disponível.
	 */
	getToken(): string | undefined {
		return this.session?.token;
	}

	/**
	 * Retorna os dados da sessão atual se existirem e não forem vazios, caso contrário retorna null.
	 * @returns Os dados da sessão ou null se a sessão for vazia ou não existir.
	 */
	public getSession(): SessionData | null {
		if (this.session && Object.keys(this.session).length > 0) {
			return this.session;
		} else {
			return null;
		}
	}

	public getUserRole(): string | undefined {
		return this.session?.role;
	}
}
