import { Demanda } from './../../../models/demanda';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Municipio } from 'src/app/models/municipio';
import { UF } from 'src/app/models/uf';
import { MunicipiosService } from 'src/app/services/municipios.service';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/auth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MultipleAlunos } from 'src/app/models/multiple-alunos';
import { DemandasService } from 'src/app/services/demandas.service';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AreaConhecimentoService } from 'src/app/services/area-conhecimento.service';
import { AreaConhecimento } from 'src/app/models/area-conhecimento';
import { Cursos } from 'src/app/models/cursos';
import { MatDialog } from '@angular/material/dialog';
import { CadastrarAlunoComponent } from '../cadastrar-aluno/cadastrar-aluno.component';
import { MultipleSelectAlunosComponent } from 'src/app/components/multiple-select-alunos/multiple-select-alunos.component';

@Component({
	selector: 'app-cadastrar-demanda',
	templateUrl: './cadastrar-demanda.component.html',
	styleUrls: ['./cadastrar-demanda.component.css'],
})
export class CadastrarDemandaComponent {
	demandaForm: FormGroup;
	ufs: string[] = [];
	areasConhecimento: AreaConhecimento[] = [];
	municipios: Municipio[] = [];
	cursos: Cursos[] = [];
	empresaId: number;
	isUpdateMode: boolean = false;
	demandaId?: number;
	title: string = 'Cadastrar Demanda';
	buttonSubmit: string = 'Criar Demanda';
	@ViewChild(MultipleSelectAlunosComponent) multipleSelectAlunosComponent?: MultipleSelectAlunosComponent;

	constructor(
		private fb: FormBuilder,
		private municipiosService: MunicipiosService,
		private areaConhecimentoService: AreaConhecimentoService,
		private authService: AuthService,
		private demandaService: DemandasService,
		private loaderService: LoaderService,
		private route: ActivatedRoute,
		private dialog: MatDialog
	) {
		this.demandaForm = this.fb.group({
			alunos: [[]],
			area_conhecimento: [''],
			curso: [{ value: '', disabled: true }, Validators.required],
			numero_vagas: [1, Validators.required],
			uf: ['', Validators.required],
			municipio: [{ value: '', disabled: true }, Validators.required],
			escola_ofertante: [''],
			observacoes: [''],
		});
		this.empresaId = this.authService.getEmpresaId()!;
	}

	/**
	 * Inicializa o componente, carregando as Unidades Federativas (UFs) disponíveis.
	 * Este método é chamado automaticamente pela plataforma Angular após a criação do componente.
	 */
	ngOnInit(): void {
		this.carregarUFs();
		this.carregarAreaConhecimento();
		this.demandaId = this.route.snapshot.params['id'];
		if (this.demandaId) {
			this.isUpdateMode = true;
			this.buttonSubmit = 'Atualizar Demanda';
			this.title = 'Atualização de Demanda';
			this.carregarDadosDemanda(this.demandaId);
		}
	}

	/**
	 * Solicita ao serviço `municipiosService` a lista de UFs disponíveis e as armazena localmente.
	 * Esta ação é disparada durante a inicialização do componente para preencher o campo de seleção de UF no formulário.
	 */
	carregarUFs(): void {
		this.municipiosService.getUfs().subscribe((ufs) => {
			this.ufs = ufs;
		});
	}

	/**
	 * Solicita ao serviço `areaConhecimentoService` a lista de Áreas do Conhecimento disponíveis e as armazena localmente.
	 * Esta ação é disparada durante a inicialização do componente para preencher o campo de seleção de Áreas do Conhecimento no formulário.
	 */
	carregarAreaConhecimento(): void {
		this.areaConhecimentoService.getAreasConhecimento().subscribe((ac) => {
			this.areasConhecimento = ac;
		});
	}

	/**
	 * Carrega os dados de uma demanda específica para edição, utilizando seu ID.
	 * @param id O ID da demanda a ser carregada.
	 */
	carregarDadosDemanda(id: number): void {
		this.loaderService.show();
		this.demandaService
			.getDemanda(id)
			.pipe(
				switchMap((demanda) => {
					// Disparar a mudança de UF/AreaConhecimento e retornar um Observable que completa quando os dados estiverem carregados.
					return new Observable((observer) => {
						this.onUfChange({ target: { value: demanda.uf } }, () => {
							observer.next(demanda);
							observer.complete();
						});
						this.onAreaConhecimentoChange(
							{ target: { value: demanda.curso!.area_conhecimento_id } },
							() => {
								observer.next(demanda);
								observer.complete();
							}
						);
					});
				})
			)
			.subscribe({
				next: (demanda: any) => {
					this.loaderService.hide();
					this.demandaForm.patchValue(this.montarDadosDemanda(demanda));
				},
				error: (error) => {
					this.loaderService.hide();
					console.error('Erro ao carregar dados da demanda', error);
					AlertService.mostrarAlertaDeErro('Erro ao carregar dados da demanda.');
				},
			});
	}

	/**
	 * Manipula a mudança de seleção no campo de UF do formulário, carregando os municípios correspondentes à UF selecionada.
	 * Este método é chamado cada vez que o usuário seleciona uma nova UF, disparando a busca por municípios dessa UF.
	 *
	 * @param event O evento que contém a UF selecionada pelo usuário.
	 */
	onUfChange(event: any, callback?: () => void): void {
		const ufSelecionada = event.target.value;
		if (ufSelecionada) {
			this.municipiosService.getMunicipiosPorUf(ufSelecionada).subscribe({
				next: (municipios) => {
					this.municipios = municipios;
					this.demandaForm.get('municipio')?.enable();
					callback?.();
				},
				error: (error) =>
					AlertService.mostrarAlertaDeErro('Algo deu errado! Entre em contato com o Administrador!'),
			});
		} else {
			this.municipios = [];
			this.demandaForm.get('municipio')?.disable();
		}
	}

	/**
	 * Manipula a mudança de seleção no campo de Area do Conhecimento do formulário, carregando os cursos correspondentes à Área selecionada.
	 * Este método é chamado cada vez que o usuário seleciona uma nova ÁREA, disparando a busca por cursos dessa Área.
	 *
	 * @param event O evento que contém a ÁREA selecionada pelo usuário.
	 */
	onAreaConhecimentoChange(event: any, callback?: () => void): void {
		const selectedId = event.target.value;
		const selectedAreaConhecimento = this.areasConhecimento.find((ac) => ac.id === +selectedId);
		this.cursos = selectedAreaConhecimento!.cursos!;
		this.demandaForm.get('curso')?.enable();
	}

	/**
	 * Submete o formulário de cadastro de demanda ao backend, validando os dados do formulário antes do envio.
	 * Se o formulário for válido, os dados são enviados ao backend. Caso contrário, uma mensagem de erro é exibida.
	 * Ao sucesso, o formulário é reiniciado e uma mensagem de sucesso é mostrada ao usuário.
	 */
	onSubmit() {
		if (this.demandaForm.valid) {
			this.loaderService.show();
			const dadosBackEnd = this.montarDadosSubmit();
			if (this.isUpdateMode) {
				this.updateDemandaCadastro(dadosBackEnd);
			} else {
				this.createDemandaCadastro(dadosBackEnd);
			}
		} else {
			AlertService.mostrarAlertaDeErro('Formulário inválido!');
			this.demandaForm.markAllAsTouched();
		}
	}

	/**
	 * Realiza a operação de criação de uma nova Demanda, enviando os dados ao backend.
	 * @param dadosBackEnd Objeto Demanda contendo os dados formatados para criação.
	 */
	createDemandaCadastro(dadosBackEnd: Demanda) {
		this.demandaService.post(dadosBackEnd).subscribe({
			next: (demanda) => {
				this.loaderService.hide();
				AlertService.mostrarAlertaDeSucesso('Demanda criada com sucesso!');
				this.demandaForm.reset();
			},
			error: (error) => {
				this.loaderService.hide();
				console.log('error: ', error);
				AlertService.mostrarAlertaDeErro(error.error.message);
			},
		});
	}

	/**
	 * Realiza a operação de atualização de uma demanda existente, enviando os dados modificados ao backend.
	 * @param dadosBackEnd Objeto Demanda contendo os dados formatados para atualização.
	 */
	updateDemandaCadastro(dadosBackEnd: Demanda) {
		const demandaId = this.demandaId!;
		this.demandaService.update(demandaId, dadosBackEnd).subscribe({
			next: (demanda) => {
				this.loaderService.hide();
				AlertService.mostrarAlertaDeSucesso('Demanda atualizada com sucesso!');
			},
			error: (error) => {
				this.loaderService.hide();
				console.log('error: ', error);
				AlertService.mostrarAlertaDeErro(error.error.message);
			},
		});
	}

	/**
	 * Monta e retorna o objeto de dados da demanda conforme esperado pelo backend, formatando os dados do formulário.
	 * Este método prepara os dados do formulário, incluindo a conversão do array de alunos para um array de IDs de alunos,
	 * para serem enviados na requisição de cadastro de demanda.
	 *
	 * @returns Objeto Demanda com os dados formatados para submissão ao backend.
	 */
	montarDadosSubmit(): Demanda {
		return {
			uf: this.demandaForm.value.uf,
			municipio_id: this.demandaForm.value.municipio,
			numero_vagas: this.demandaForm.value.numero_vagas,
			alunos_ids: this.demandaForm.value.alunos.map((aluno: MultipleAlunos) => aluno.id),
			escola_ofertante: this.demandaForm.value.escola_ofertante,
			observacoes: this.demandaForm.value.observacoes,
			empresa_id: this.empresaId,
			curso_id: this.demandaForm.value.curso,
		};
	}

	/**
	 * Estrutura os dados de uma `Demanda` para popular corretamente o front-end.
	 *
	 * Este método recebe um objeto `Demanda` como entrada e retorna um objeto contendo
	 * informações essenciais e formatadas da demanda, incluindo uma lista dos alunos
	 * relacionados com apenas seus `id` e `nome`.
	 *
	 * @param {Demanda} demanda - O objeto `Demanda` contendo os dados a serem estruturados.
	 * @returns {any} Um objeto contendo os dados estruturados da demanda, incluindo
	 *                uma lista simplificada de alunos e outros atributos da demanda.
	 */
	montarDadosDemanda(demanda: Demanda): any {
		return {
			alunos: demanda.alunos?.map((aluno) => {
				return { id: aluno.id, nome: aluno.nome };
			}),
			numero_vagas: demanda.numero_vagas,
			uf: demanda.uf,
			area_conhecimento: demanda.curso!.area_conhecimento_id,
			municipio: demanda.municipio_id,
			curso: demanda.curso_id,
			escola_ofertante: demanda.escola_ofertante,
			observacoes: demanda.observacoes,
		};
	}

	/**
	 * Abre o modal para cadastro de aluno.
	 *
	 * Este método abre o modal utilizando o MatDialog, especificamente para o componente CadastrarAlunoComponent.
	 * Após o fechamento do modal, independente do resultado, este método inicia a exibição do indicador de carregamento,
	 * chama o método carregarAlunos() do componente MultipleSelectAlunosComponent para atualizar a lista de alunos, e então
	 * oculta o indicador de carregamento.
	 */
	openCadastrarAlunoModal(): void {
		const dialogRef = this.dialog.open(CadastrarAlunoComponent, {});

		dialogRef.afterClosed().subscribe((result) => {
			this.loaderService.show();
			this.multipleSelectAlunosComponent?.carregarAlunos();
			this.loaderService.hide();
		});
	}
}
