import { HttpParams } from '@angular/common/http';

/**
 * Formata uma data de DDMMYYYY para YYYY-MM-DD.
 * @param data String contendo a data no formato DDMMYYYY.
 * @returns A data formatada como YYYY-MM-DD, ou a entrada original se o formato não corresponder.
 */
export function formatarDataBackEnd(data: string): string {
	if (data && data.length === 8) {
		const dia = data.substring(0, 2);
		const mes = data.substring(2, 4);
		const ano = data.substring(4);
		return `${ano}-${mes}-${dia}`;
	}
	return data;
}

/**
 * Formata uma data de YYYY-MM-DD para DDMMYYYY.
 * @param data String contendo a data no formato YYYY-MM-DD.
 * @returns A data formatada como DDMMYYYY, ou a entrada original se o formato não corresponder.
 */
export function formatarDataFrontEnd(data: string): string {
	// Verifica se a data está no formato esperado YYYY-MM-DD
	const regex = /^\d{4}-\d{2}-\d{2}$/;
	if (regex.test(data)) {
		const partes = data.split('-'); // Divide a string de data em partes [ano, mes, dia]
		const ano = partes[0];
		const mes = partes[1];
		const dia = partes[2];
		return `${dia}${mes}${ano}`; // Retorna a data formatada como DDMMYYYY
	}
	return data; // Retorna a entrada original se o formato não corresponder
}

/**
 * Adiciona parâmetros dinamicamente a um objeto HttpParams com base nos filtros fornecidos.
 *
 * Itera sobre cada propriedade do objeto de filtros. Para cada propriedade que possui um valor verdadeiro,
 * adiciona esse par chave-valor ao objeto HttpParams. Isso é útil para construir parâmetros de consulta
 * para uma requisição HTTP de forma dinâmica, baseando-se em um conjunto de filtros possivelmente variável.
 *
 * @param filtros Um objeto contendo os filtros a serem adicionados como parâmetros. Cada chave do objeto
 * representa o nome do parâmetro, e o valor associado a essa chave é o valor do parâmetro.
 * @param params Uma instância de HttpParams à qual os parâmetros serão adicionados. Isso permite encadear
 * a adição de parâmetros a partir de um objeto HttpParams existente.
 *
 * @returns Uma nova instância de HttpParams contendo os parâmetros originais, além dos adicionados
 * dinamicamente a partir do objeto de filtros.
 */
export function adicionarParamsDinamicamente(filtros: any, params: HttpParams) {
	Object.keys(filtros).forEach((key) => {
		if (filtros[key]) {
			params = params.append(key, filtros[key]);
		}
	});

	return params;
}

export function convertDateToString(date: Date | null): string | null {
    if (date) {
        return date.toISOString().split('T')[0];
    }
    return null;
}

