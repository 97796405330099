export const Constants = {
	Roles: {
		ADMIN: 'admin',
	},
	Routes: {
		FullPaths: {
			Empresas: {
				Demandas: {
					CADASTRAR: '/empresas/demandas/cadastrar',
				},
			},
			Admin: {},
			Public: {},
		},
		ModulePaths: {
			Empresas: {
				Demandas: {
					HOME: 'demandas',
					CADASTRAR: 'demandas/cadastrar',
					CADASTRAR_ID: 'demandas/cadastrar/:id',
				},
				Alunos: {
					HOME: 'alunos',
					CADASTRAR: 'alunos/cadastrar',
					CADASTRAR_ID: 'alunos/cadastrar/:id',
				},
			},
			Admin: {
				AreaConhecimento: {
					HOME: 'area-conhecimento',
					CADASTRAR: 'area-conhecimento/cadastrar',
					CADASTRAR_ID: 'area-conhecimento/cadastrar/:id',
				},
				Cursos: {
					HOME: 'cursos',
					CADASTRAR: 'cursos/cadastrar',
					CADASTRAR_ID: 'cursos/cadastrar/:id',
				},
				Demandas: {
					HOME: 'demandas',
				},
			},
			Public: {
				Cursos: {
					HOME_ID: 'cursos/:id',
					AREA_CONHECIMENTO: 'cursos/area-conhecimento',
					AREA_CONHECIMENTO_ID: 'cursos/area-conhecimento/:id',
				}
			},
		},
	},
};
