import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { adicionarParamsDinamicamente } from '../utils/utils';

@Injectable({
	providedIn: 'root',
})
export class ExportService {
  private baseUrl: string = environment.apiUrl + '/export';

	constructor(private http: HttpClient) {}

	exportCursos(filtros: any): Observable<Blob> {
		let params = adicionarParamsDinamicamente(filtros, new HttpParams());
		return this.http.get(`${this.baseUrl}/cursos`, {
			responseType: 'blob',
			headers: new HttpHeaders({
				Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			}),
			params: params
		});
	}

	exportDemandas(filtros: any): Observable<Blob> {
		let params = adicionarParamsDinamicamente(filtros, new HttpParams());
		return this.http.get(`${this.baseUrl}/demandas`, {
			responseType: 'blob',
			headers: new HttpHeaders({
				Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			}),
			params: params
		});
	}
}
