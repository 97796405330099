import { AfterViewInit, Component, Input, OnInit, SimpleChanges, ViewChild, EventEmitter, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { TableColumn } from 'src/app/models/table-column';
import { BotaoTable } from 'src/app/models/botao-cadastrar-table';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteDialogComponent } from '../confirm-delete-dialog/confirm-delete-dialog.component';
import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loader.service';

/**
 * Componente responsável pela exibição de dados em formato de tabela.
 * Este componente é genérico e pode ser reutilizado para exibir qualquer conjunto de dados
 * passando as colunas e os dados como parâmetros.
 */
@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.css'],
})
export class TableComponent implements OnInit, AfterViewInit {
	/**
	 * Output no Componente pai, responsável por monitor evento de exportaçao.
	 */
	@Output() eventoExportar = new EventEmitter<void>();

	/**
	 * Service correspondente ao componente pai.
	 */
	@Input() service: any;

	/**
	 * URL de Redirecionamento para tela de atualização do Elemento.
	 */
	@Input() updateUrl: string | null = '';

	/**
	 * URL de Redirecionamento para tela de detalhe do Elemento.
	 */
	@Input() detailUrl: string | null = '';

	/**
	 * Colunas da tabela definidas pelo usuário do componente.
	 * Cada coluna pode incluir definições como o cabeçalho da coluna e como os dados devem ser exibidos.
	 */
	@Input() columns: TableColumn[] = [];

	/**
	 * Dados a serem exibidos na tabela.
	 */
	@Input() data: any[] = [];

	/**
	 * Configuração opcional para um botão de cadastro que pode ser exibido junto à tabela.
	 */
	@Input() botaoCadastrarTable?: BotaoTable = {
		deveExibir: false,
		titulo: '',
		routerLink: '',
	};

	/**
	 * Configuração opcional para um botão de exportação que pode ser exibido junto à tabela.
	 */
	@Input() botaoExportarTable?: BotaoTable = {
		deveExibir: false,
		titulo: '',
		routerLink: '',
	};

	/**
	 * Referência ao paginador da tabela para controle de paginação.
	 */
	@ViewChild(MatPaginator) paginator!: MatPaginator;

	/**
	 * Referência à funcionalidade de ordenação da tabela.
	 */
	@ViewChild(MatSort) sort!: MatSort;

	/**
	 * Fonte de dados para a tabela do Angular Material, inicializada com um array vazio.
	 */
	dataSource = new MatTableDataSource<any>([]);

	/**
	 * Flag para controlar a exibição da linha de carregamento.
	 */
	@Input() isLoadingResults: boolean = true;

	/**
	 * Flag para controlar a exibição da linha de carregamento.
	 */
	@Input() deleteMessage: string = '';

	/**
	 * Flag para controlar a existencia de dados atual.
	 */
	hasData: boolean = true;

	constructor(
		private _liveAnnouncer: LiveAnnouncer,
		private router: Router,
		private dialog: MatDialog,
		private loaderService: LoaderService
	) {}

	ngOnInit(): void {}

	ngAfterViewInit() {
		// Configura a fonte de dados para usar paginador e ordenação.
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	ngOnChanges(changes: SimpleChanges): void {
		// Atualiza a fonte de dados quando os dados de entrada mudam.
		if (changes['data']) {
			this.dataSource.data = this.data;
		}
	}

	/**
	 * Anuncia mudanças no estado de ordenação para tecnologias assistivas.
	 * @param sortState O estado atual da ordenação.
	 */
	announceSortChange(sortState: Sort) {
		if (sortState.direction) {
			this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
		} else {
			this._liveAnnouncer.announce('Sorting cleared');
		}
	}

	/**
	 * Aplica um filtro aos dados da tabela baseado no valor de entrada do usuário.
	 * @param event O evento de entrada que contém o valor do filtro.
	 */
	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	/**
	 * Retorna um array das definições de colunas para uso no template.
	 */
	get displayedColumns() {
		return this.columns.map((c) => c.columnDef);
	}

	/**
	 * Placeholder para método de redirect.
	 * @param element O elemento a ser redirecionado.
	 */
	redirect(element: any) {
		console.log(`${this.detailUrl}/${element.id}`);
		this.router.navigateByUrl(`${this.detailUrl}/${element.id}`);
	}

	/**
	 * Placeholder para método de edição.
	 * @param element O elemento a ser editado.
	 */
	edit(element: any) {
		this.router.navigateByUrl(`${this.updateUrl}/${element.id}`);
	}

	/**
	 * Placeholder para método de exclusão.
	 * @param element O elemento a ser excluído.
	 */
	delete(element: any) {
		const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
			width: '450px',
			data: { deleteMessage: this.deleteMessage },
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.loaderService.show();
				this.service.delete(element.id).subscribe({
					next: () => {
						this.loaderService.hide();
						AlertService.mostrarAlertaDeSucesso('Deletado com sucesso!');
						const newData = this.dataSource.data.filter((data) => data.id !== element.id);
						this.updateDataSource(newData);
					},
					error: (error: any) => {
						this.loaderService.hide();
						console.log('error: ', error);
						AlertService.mostrarAlertaDeErro(error.error.message);
					},
				});
			}
		});
	}

	/**
	 * Atualiza a fonte de dados da tabela com novos dados e notifica a tabela para recalcular e atualizar a visualização.
	 *
	 * @param newData O novo conjunto de dados para a tabela, após a remoção de um elemento.
	 */
	updateDataSource(newData: any[]): void {
		this.dataSource.data = newData;
		this.dataSource._updateChangeSubscription();
	}

	/**
	 * Método responsável por emitir o evento de Exportar para o componente pai.
	 */
	exportarRelatorio() {
		this.eventoExportar!.emit();
	}
}
