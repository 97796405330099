import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApiResponse } from '../models/api-response';
import { Aluno } from '../models/aluno';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class AlunosService {
	private baseUrl: string = environment.apiUrl + '/alunos';

	constructor(private http: HttpClient) {}

	/**
	 * Obtém a lista de todos os alunos.
	 * @returns Observable de um array de Alunos.
	 */
	getAlunos(): Observable<Aluno[]> {
		return this.http.get<ApiResponse<Aluno[]>>(this.baseUrl).pipe(map((response) => response.data));
	}

	/**
	 * Obtém os detalhes de um aluno específico pelo seu ID.
	 * @param id O ID do aluno.
	 * @returns Observable de Aluno.
	 */
	getAluno(id: number): Observable<Aluno> {
		const url = `${this.baseUrl}/${id}`;
		return this.http.get<ApiResponse<Aluno>>(url).pipe(map((response) => response.data));
	}

	/**
	 * Cadastra um novo aluno.
	 * @param aluno O objeto Aluno a ser cadastrado.
	 * @returns Observable do Aluno cadastrado.
	 */
	postAlunoCadastro(aluno: Aluno): Observable<Aluno> {
		return this.http.post<ApiResponse<Aluno>>(this.baseUrl, aluno).pipe(map((response) => response.data));
	}

	/**
	 * Atualizar dados de um aluno.
	 * @param alunoId O ID do Aluno a ser atualizado.
	 * @param aluno O objeto Aluno a ser atualizado.
	 * @returns Observable do Aluno cadastrado.
	 */
	updateAlunoCadastro(alunoId: number, aluno: Aluno): Observable<Aluno> {
		return this.http
			.put<ApiResponse<Aluno>>(`${this.baseUrl}/${alunoId}`, aluno)
			.pipe(map((response) => response.data));
	}

	/**
	 * Deleta um aluno específico pelo seu ID.
	 * @param id O ID do aluno.
	 * @returns Observable de Aluno.
	 */
	delete(alunoId: number): Observable<any> {
		return this.http.delete(`${this.baseUrl}/${alunoId}`);
	}

	/**
	 * Obtém a lista de todos os alunos por empresa.
	 * @returns Observable de um array de Alunos.
	 */
	getAlunosByEmpresa(empresaId: number): Observable<Aluno[]> {
		return this.http
			.get<ApiResponse<Aluno[]>>(`${this.baseUrl}/por-empresa/${empresaId}`)
			.pipe(map((response) => response.data));
	}
}
