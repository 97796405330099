<h2 mat-dialog-title>Confirmação</h2>
<div *ngIf="data?.deleteMessage" class="icon-center">
	<i class="fas fa-exclamation-triangle icon-warning" aria-hidden="true"></i>
</div>
<h4 mat-dialog-title *ngIf="data?.deleteMessage" class="message-left">{{ data.deleteMessage }}</h4>
<mat-dialog-content>Deseja realmente excluir este item?</mat-dialog-content>
<mat-dialog-actions align="end" class="mb-2">
	<button [mat-dialog-close]="true" class="br-button primary mr-1" type="button">Excluir</button>
	<button mat-dialog-close class="br-button secondary" type="button">Cancelar</button>
</mat-dialog-actions>
