import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'phone',
})
export class PhonePipe implements PipeTransform {
	transform(value: string): string {
		// Verifica se o valor é válido
		if (!value) {
			return value;
		}

		// Extrai os componentes do número de telefone
		const ddd = value.slice(0, 2);
    const prefixo = value.slice(2, 7);
    const sufixo = value.slice(7);

		// Monta o número de telefone formatado
		return `(${ddd}) ${prefixo}-${sufixo}`;
	}
}
