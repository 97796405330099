import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AreaConhecimento } from '../models/area-conhecimento';
import { ApiResponse } from '../models/api-response';

@Injectable({
	providedIn: 'root',
})
export class AreaConhecimentoService {
	private baseUrl: string = environment.apiUrl + '/area-conhecimento';

	constructor(private http: HttpClient) {}

	/**
	 * Busca todas as áreas de conhecimento cadastradas.
	 * @returns Um Observable com um array de objetos AreaConhecimento.
	 */
	getAreasConhecimento(): Observable<AreaConhecimento[]> {
		return this.http.get<ApiResponse<AreaConhecimento[]>>(this.baseUrl).pipe(map((response) => response.data));
	}

	/**
	 * Busca uma área de conhecimento específica pelo seu ID.
	 * @param id O ID da área de conhecimento desejada.
	 * @returns Um Observable de um objeto AreaConhecimento.
	 */
	getAreaConhecimento(id: number): Observable<AreaConhecimento> {
		const url = `${this.baseUrl}/${id}`;
		return this.http.get<ApiResponse<AreaConhecimento>>(url).pipe(map((response) => response.data));
	}

	/**
	 * Cria uma nova área de conhecimento no sistema.
	 * @param areaConhecimento O objeto AreaConhecimento para criação.
	 * @returns Um Observable do objeto AreaConhecimento criado.
	 */
	post(areaConhecimento: AreaConhecimento): Observable<AreaConhecimento> {
		return this.http
			.post<ApiResponse<AreaConhecimento>>(this.baseUrl, areaConhecimento)
			.pipe(map((response) => response.data));
	}

	/**
	 * Deleta uma ac específica pelo seu ID.
	 * @param id O ID da AC.
	 * @returns Observable de AC.
	 */
	delete(areConhecimentoId: number): Observable<any> {
		return this.http.delete(`${this.baseUrl}/${areConhecimentoId}`);
	}

	/**
	 * Atualizar dados de uma AC.
	 * @param areConhecimentoId O ID da AC a ser atualizada.
	 * @param areaConhecimento O objeto AC a ser atualizado.
	 * @returns Observable da AC cadastrada.
	 */
	update(areConhecimentoId: number, areaConhecimento: AreaConhecimento): Observable<AreaConhecimento> {
		return this.http
			.put<ApiResponse<AreaConhecimento>>(`${this.baseUrl}/${areConhecimentoId}`, areaConhecimento)
			.pipe(map((response) => response.data));
	}
}
