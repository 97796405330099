<nav class="navbar navbar-expand-lg navbar-light bg-light">
	<div class="container-fluid">
		<h4>{{pageTitle}}</h4>
		<span class="br-divider vertical mx-3"></span>
		<button
			class="navbar-toggler"
			type="button"
			data-bs-toggle="collapse"
			data-bs-target="#navbarNavDropdown"
			aria-controls="navbarNavDropdown"
			aria-expanded="false"
			aria-label="Toggle navigation"
		>
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarNavDropdown">
			<ul class="navbar-nav me-auto">
				<li *ngFor="let r of routes" class="nav-item">
					<a
						class="nav-link"
						routerLink="{{ r.path }}"
						routerLinkActive="nav-link-active"
						[routerLinkActiveOptions]="{ exact: true }"
						>{{ r.titlePath }}
					</a>
				</li>
			</ul>
			<div class="dropdown">
				<button
					class="btn br-sign-in dropdown-toggle"
					type="button"
					id="avatar-dropdown-trigger"
					data-bs-toggle="dropdown"
					data-bs-target="#userDropdown"
					aria-expanded="false"
				>
					<span class="br-avatar" title="{{userName}}"
						><span class="content bg-orange-vivid-30 text-pure-0">{{userName[0]}}</span></span
					>
					<span class="ml-2 text-gray-80 text-weight-regular"
						>Olá, <span class="text-weight-semi-bold">{{userName}}</span></span
					>
				</button>
				<ul id="userDropdown" class="dropdown-menu" aria-labelledby="avatar-dropdown-trigger">
					<li class="border-divisor"><a class="dropdown-item" href="javascript:void(0)">Editar Dados</a></li>
					<li>
						<a class="dropdown-item" href="javascript:void(0)" (click)="logout()">Logout</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</nav>
