import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { RoutesInterface } from 'src/app/models/routes-interface';
import { SessionData } from 'src/app/models/session-data';
import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
	selector: 'app-navbar-top',
	templateUrl: './navbar-top.component.html',
	styleUrls: ['./navbar-top.component.css'],
})
export class NavbarTopComponent {
	/**
	 * As rotas a serem exibidas na barra de navegação, conforme definido pelo componente pai.
	 */
	@Input() routes: RoutesInterface[] = [];

	/**
	 * O título da página atual, exibido na barra de navegação.
	 */
	@Input() pageTitle: string = '';

	/**
	 * O nome do usuário logado, inicializado como 'Usuário' e atualizado com base na sessão.
	 */
	userName: string = 'Usuário';

	/**
	 * Construtor que inicializa os serviços necessários e carrega o nome do usuário.
	 */
	constructor(private loaderService: LoaderService, private authService: AuthService, private router: Router) {
		this.loadUserName();
	}

	/**
	 * Carrega o nome do usuário a partir da sessão armazenada no localStorage, se disponível.
	 */
	loadUserName() {
		const sessionString = localStorage.getItem('session');
		let session: SessionData;
		if (sessionString) {
			session = JSON.parse(sessionString);
			this.userName = session.user.nome;
		}
	}

	/**
	 * Realiza o logout do usuário, mostrando o loader durante o processo e tratando o sucesso ou erro.
	 */
	logout() {
		this.loaderService.show();
		this.authService.logout().subscribe({
			next: (response) => {
				this.loaderService.hide();
			},
			error: (error) => {
				this.loaderService.hide();
				AlertService.mostrarAlertaDeErro('Tentativa de Logout não funcionou como esperado');
				this.router.navigateByUrl('/');
			},
		});
	}
}
