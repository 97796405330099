import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-login-form',
	templateUrl: './login-form.component.html',
	styleUrls: ['./login-form.component.css'],
})
export class LoginFormComponent implements OnInit {
	/**
	 * A role do usuário, 'empresa' ou 'admin', determinando o tipo de login.
	 */
	@Input() role!: 'empresa' | 'admin';

	/**
	 * O rótulo do input, indicando se é para CNPJ ou CPF, baseado na role.
	 */
	@Input() inputLabel!: string;

	/**
	 * A máscara de entrada do input, formatando o campo conforme CNPJ ou CPF.
	 */
	@Input() inputMask!: string;

	/**
	 * Evento emitido ao realizar o login, enviando os dados do formulário.
	 */
	@Output() loginEvent = new EventEmitter<{ userName: string; password: string }>();

	/**
	 * Formulário para os dados de login, incluindo validações.
	 */
	form!: FormGroup;

	/**
	 * Placeholder do campo de userName, que será ajustado conforme a role.
	 */
	placeHolder: string = '';

	/**
	 * Construtor que inicializa o FormBuilder.
	 */
	constructor(private fb: FormBuilder) {}

	/**
	 * Na inicialização do componente, configura o formulário de login e ajusta o placeholder.
	 */
	ngOnInit(): void {
		this.form = this.fb.group({
			userName: ['', Validators.required],
			password: ['', Validators.required],
		});
		this.placeHolder = `Digite seu ${this.inputLabel}`;
	}

	/**
	 * Realiza o login, emitindo os dados do formulário caso seja válido.
	 */
	login(): void {
		if (this.form.valid) {
			this.loginEvent.emit(this.form.value);
		}
	}
}
