import { Component } from '@angular/core';

interface Link {
  href: string;
  name: string;
  target: string;
  title: string;
}
@Component({
  selector: 'br-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class BrHeaderComponent {
  public title = 'Qualifica PAC';
  public subtitle = 'Transformando o futuro em Verde';
  public signature: string = 'Transformando o futuro em Verde';

  public image = {
    src: 'assets/logo_angular.png',
    alt: 'Logo de exemplo',
  };

  public links: Link[] = [];

  isRotated: boolean = false;

  toggleRotation() {
    this.isRotated = !this.isRotated;
  }
}
