<div class="form-group">
	<label for="empresas">Alunos</label>
	<ng-multiselect-dropdown
		*ngIf="dropdownSettings"
		[placeholder]="'Selecionar Alunos'"
		[data]="alunos"
		[settings]="dropdownSettings"
		[(ngModel)]="alunosSelecionados"
    (onSelect)="onItemSelect($event)"
    (onDeSelect)="onItemDeSelect($event)"
    (onSelectAll)="onSelectAll($event)"
    (onDeSelectAll)="onDeselectAll($event)"
	>
	</ng-multiselect-dropdown>
</div>
