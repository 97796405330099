import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from './../models/api-response';
import { Empresa } from '../models/empresa';
import { environment } from 'src/environments/environment';
import { SetorEconomico } from '../models/setor-economico';

/**
 * Serviço responsável pela comunicação com a API de Empresas.
 * Permite realizar operações CRUD sobre as empresas e recuperar os setores econômicos.
 */
@Injectable({
	providedIn: 'root',
})
export class EmpresasService {
	private baseUrl: string = environment.apiUrl + '/empresas';
	private setorEconomicoBaseUrl: string = this.baseUrl + '/setor-economico';

	constructor(private http: HttpClient) {}

	/**
	 * Obtém a lista completa de empresas.
	 * @returns Um Observable contendo um array de Empresas.
	 */
	getEmpresas(): Observable<Empresa[]> {
		return this.http.get<ApiResponse<Empresa[]>>(this.baseUrl).pipe(map((response) => response.data));
	}

	/**
	 * Obtém os detalhes de uma empresa específica pelo seu ID.
	 * @param id O ID da empresa.
	 * @returns Um Observable contendo os detalhes da Empresa.
	 */
	getEmpresa(id: number): Observable<Empresa> {
		const url = `${this.baseUrl}/${id}`;
		return this.http.get<ApiResponse<Empresa>>(url).pipe(map((response) => response.data));
	}

	/**
	 * Cadastra uma nova empresa.
	 * @param empresa O objeto Empresa a ser cadastrado.
	 * @returns Um Observable contendo a Empresa cadastrada.
	 */
	post(empresa: Empresa): Observable<Empresa> {
		return this.http.post<ApiResponse<Empresa>>(this.baseUrl, empresa).pipe(map((response) => response.data));
	}

	/**
	 * Obtém a lista de setores econômicos disponíveis.
	 * @returns Um Observable contendo um array de Setores Econômicos.
	 */
	getSetoresEconomicos(): Observable<SetorEconomico[]> {
		return this.http
			.get<ApiResponse<SetorEconomico[]>>(this.setorEconomicoBaseUrl)
			.pipe(map((response) => response.data));
	}
}
