<div class="page-container">
	<div class="container full-height">
		<div class="row justify-content-center w-100">
			<div class="col-10 form-container">
				<div class="row g-0 align-items-center">
					<div class="col-lg-4 form-image"></div>
					<div class="col-lg-8 p-4 my-4">
						<div class="container px-4 py-5" id="featured-3">
							<h2 class="pb-2 border-bottom">Cadastro da Empresa</h2>
						</div>

						<div class="container">
							<form [formGroup]="empresaForm" (ngSubmit)="onSubmit()">
								<div class="row mb-4">
									<div class="col-12 col-md-8">
										<div class="form-outline">
											<label class="form-label" for="nome_empresa">Nome da Empresa</label>
											<div class="input-group">
												<span class="input-group-text"><i class="fa fa-building"></i></span>
												<input
													type="text"
													id="nome_empresa"
													formControlName="nome_empresa"
													class="form-control"
													[ngClass]="{
														'is-invalid':
															empresaForm.get('nome_empresa')?.touched &&
															empresaForm.get('nome_empresa')?.invalid
													}"
												/>
												<div
													*ngIf="
														empresaForm.get('nome_empresa')?.touched &&
														empresaForm.get('nome_empresa')?.invalid
													"
													class="invalid-feedback"
												>
													Campo obrigatório.
												</div>
											</div>
										</div>
									</div>
									<div class="col-12 col-md-4">
										<div class="form-outline">
											<label class="form-label" for="cnpj">CNPJ</label>
											<div class="input-group">
												<span class="input-group-text"><i class="fa fa-id-card"></i></span>
												<input
													type="text"
													id="cnpj"
													formControlName="cnpj"
													class="form-control"
													mask="00.000.000/0000-00"
													[ngClass]="{
														'is-invalid':
															empresaForm.get('cnpj')?.touched &&
															empresaForm.get('cnpj')?.invalid
													}"
												/>
												<div
													*ngIf="
														empresaForm.get('cnpj')?.touched &&
														empresaForm.get('cnpj')?.invalid
													"
													class="invalid-feedback"
												>
													Campo obrigatório.
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="row mb-4">
									<div class="col-12 col-md-6">
										<div class="form-outline">
											<label class="form-label" for="senha">Senha</label>
											<div class="input-group">
												<span class="input-group-text"><i class="fa fa-key"></i></span>
												<input
													type="password"
													id="senha"
													formControlName="senha"
													class="form-control"
													[type]="mostrarSenha ? 'text' : 'password'"
													[ngClass]="{
														'is-invalid':
															empresaForm.get('senha')?.touched &&
															empresaForm.get('senha')?.invalid
													}"
												/>
												<button class="input-group-text" type="button" (click)="toggleSenha()">
													<i
														class="fa"
														[class.fa-eye-slash]="mostrarSenha"
														[class.fa-eye]="!mostrarSenha"
													></i>
												</button>
												<div
													*ngIf="
														empresaForm.get('senha')?.touched &&
														empresaForm.get('senha')?.invalid
													"
													class="invalid-feedback"
												>
													Senha deve ter no mínimo 6 algarismos.
												</div>
											</div>
										</div>
									</div>
									<div class="col-12 col-md-6">
										<div class="form-outline">
											<label class="form-label" for="confirmarSenha">Confirmar Senha</label>
											<div class="input-group">
												<span class="input-group-text"><i class="fa fa-key"></i></span>
												<input
													type="password"
													id="confirmarSenha"
													formControlName="confirmarSenha"
													class="form-control"
													[type]="mostrarSenha ? 'text' : 'password'"
													[ngClass]="{'is-invalid': empresaForm.errors?.['senhaNaoConfere'] && empresaForm.touched}"
												/>
												<button class="input-group-text" type="button" (click)="toggleSenha()">
													<i
														class="fa"
														[class.fa-eye-slash]="mostrarSenha"
														[class.fa-eye]="!mostrarSenha"
													></i>
												</button>
												<div
													*ngIf="empresaForm.errors?.['senhaNaoConfere'] && empresaForm.get('confirmarSenha')?.touched"
													class="invalid-feedback"
												>
													As senhas não conferem.
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="row mb-4">
									<div class="col-2 col-md-2">
										<div class="form-outline">
											<label class="form-label" for="uf">UF</label>
											<div class="input-group">
												<span class="input-group-text"><i class="fa fa-globe"></i></span>
												<select
													class="form-select"
													formControlName="uf"
													aria-label="Selecione uma UF"
													(change)="onUfChange($event)"
												>
													<option value="" disabled selected>UF</option>
													<option *ngFor="let uf of ufs" [value]="uf">
														{{ uf }}
													</option>
												</select>
											</div>
										</div>
									</div>

									<div class="col-10 col-md-10">
										<div class="form-outline">
											<label class="form-label" for="municipio">Município</label>
											<div class="input-group">
												<span class="input-group-text"><i class="fa fa-map-marker"></i></span>
												<select
													class="form-select"
													formControlName="municipio"
													aria-label="Selecione um Município"
													[ngClass]="{
														'is-invalid':
															empresaForm.get('municipio')?.touched &&
															empresaForm.get('municipio')?.invalid
													}"
												>
													<option value="" disabled selected>Selecione um Município</option>
													<option *ngFor="let municipio of municipios" [value]="municipio.id">
														{{ municipio.nome }}
													</option>
												</select>
												<div
													*ngIf="
														empresaForm.get('municipio')?.touched &&
														empresaForm.get('municipio')?.invalid
													"
													class="invalid-feedback"
												>
													Campo obrigatório.
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row mb-4">
									<div class="col-12 col-md-5">
										<div class="form-outline">
											<label class="form-label" for="email">Email</label>
											<div class="input-group">
												<span class="input-group-text"><i class="fa fa-envelope"></i></span>
												<input
													type="email"
													id="email"
													formControlName="email"
													class="form-control"
													[ngClass]="{
														'is-invalid':
															empresaForm.get('email')?.touched &&
															empresaForm.get('email')?.invalid
													}"
												/>
												<div
													*ngIf="empresaForm.get('email')?.errors?.['required'] && empresaForm.get('email')?.touched"
													class="invalid-feedback"
												>
													Email é obrigatório.
												</div>
												<div
													*ngIf="empresaForm.get('email')?.errors?.['email'] && empresaForm.get('email')?.touched"
													class="invalid-feedback"
												>
													Por favor, insira um endereço de email válido.
												</div>
											</div>
										</div>
									</div>
									<div class="col-12 col-md-7">
										<div class="form-outline">
											<label class="form-label" for="setor_economico">Setor Econômico</label>
											<div class="input-group">
												<span class="input-group-text"><i class="fa fa-list"></i></span>
												<select
													class="form-select"
													formControlName="setor_economico"
													aria-label="Selecione um Setor Econômico"
												>
													<option value="" disabled selected>
														Selecione um Setor Econômico
													</option>
													<option *ngFor="let se of setorEconomico" [value]="se.id">
														{{ se.descricao }}
													</option>
												</select>
											</div>
										</div>
									</div>
								</div>

								<div class="row mb-4">
									<div class="col">
										<div class="form-outline">
											<label class="form-label" for="endereco">Endereço</label>
											<div class="input-group">
												<span class="input-group-text"
													><i class="fa fa-location-arrow"></i
												></span>
												<input
													type="text"
													id="endereco"
													formControlName="endereco"
													class="form-control"
													[ngClass]="{
														'is-invalid':
															empresaForm.get('endereco')?.touched &&
															empresaForm.get('endereco')?.invalid
													}"
												/>
												<div
													*ngIf="
														empresaForm.get('endereco')?.touched &&
														empresaForm.get('endereco')?.invalid
													"
													class="invalid-feedback"
												>
													Campo obrigatório.
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="row mb-4">
									<div class="col-12 col-md-6">
										<div class="form-outline">
											<label class="form-label" for="cep">CEP</label>
											<div class="input-group">
												<span class="input-group-text"><i class="fa fa-envelope"></i></span>
												<input
													type="text"
													id="cep"
													formControlName="cep"
													class="form-control"
													mask="00000-000"
													[ngClass]="{
														'is-invalid':
															empresaForm.get('cep')?.touched &&
															empresaForm.get('cep')?.invalid
													}"
												/>
												<div
													*ngIf="
														empresaForm.get('cep')?.touched &&
														empresaForm.get('cep')?.invalid
													"
													class="invalid-feedback"
												>
													Campo obrigatório.
												</div>
											</div>
										</div>
									</div>
									<div class="col-12 col-md-6">
										<div class="form-outline">
											<label class="form-label" for="telefone">Telefone</label>
											<div class="input-group">
												<span class="input-group-text"><i class="fa fa-phone"></i></span>
												<input
													type="text"
													id="telefone"
													formControlName="telefone"
													class="form-control"
													mask="(00) 00000-0000"
													[ngClass]="{
														'is-invalid':
															empresaForm.get('telefone')?.touched &&
															empresaForm.get('telefone')?.invalid
													}"
												/>
												<div
													*ngIf="
														empresaForm.get('telefone')?.touched &&
														empresaForm.get('telefone')?.invalid
													"
													class="invalid-feedback"
												>
													Campo obrigatório.
												</div>
											</div>
										</div>
									</div>
								</div>

								<!-- Submit button -->
								<button
									class="br-button primary mr-3 mt-3"
									type="submit"
									[disabled]="empresaForm.invalid"
								>
									Criar Empresa
								</button>
								<button class="br-button secondary mr-3 mt-3" type="button" routerLink="/login">
									Voltar
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
