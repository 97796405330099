import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiResponse } from './../models/api-response';
import { Municipio } from '../models/municipio';
import { UF } from '../models/uf';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class MunicipiosService {
	private ufsCache: string[] | null = null;
	private baseMunicipioUrl: string = `${environment.apiUrl}/municipios`;

	constructor(private http: HttpClient) {}

	getMunicipios(): Observable<Municipio[]> {
		return this.http.get<ApiResponse<Municipio[]>>(this.baseMunicipioUrl).pipe(map((response) => response.data));
	}

	getMunicipio(id: number): Observable<Municipio> {
		const url = `${this.baseMunicipioUrl}/${id}`;
		return this.http.get<ApiResponse<Municipio>>(url).pipe(map((response) => response.data));
	}

	getMunicipiosPorUf(uf: string): Observable<Municipio[]> {
		const url = `${this.baseMunicipioUrl}/por-uf/${uf}`;
		return this.http.get<ApiResponse<Municipio[]>>(url).pipe(map((response) => response.data));
	}

	getUfs(): Observable<string[]> {
		// Verifica se os dados já estão no cache
		if (this.ufsCache) {
			return of(this.ufsCache);
		}

		// Faz a requisição ao backend se os dados não estiverem no cache
		return this.http.get<ApiResponse<string[]>>(`${this.baseMunicipioUrl}/ufs`).pipe(
			map((response) => response.data),
			tap((ufs) => {
				this.ufsCache = ufs;
			})
		);
	}
}
