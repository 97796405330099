import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/api-response';
import { Demanda } from '../models/demanda';
import { adicionarParamsDinamicamente } from '../utils/utils';

@Injectable({
	providedIn: 'root',
})
export class DemandasService {
	private baseUrl: string = environment.apiUrl + '/demandas';

	constructor(private http: HttpClient) {}

	/**
	 * Obtém a lista de todas as demandas.
	 * @returns Observable de um array de Demandas.
	 */
	getDemandas(): Observable<Demanda[]> {
		return this.http.get<ApiResponse<Demanda[]>>(this.baseUrl).pipe(map((response) => response.data));
	}

	/**
	 * Obtém os detalhes de uma demanda específica pelo seu ID.
	 * @param id O ID da demanda.
	 * @returns Observable de Demanda.
	 */
	getDemanda(id: number): Observable<Demanda> {
		const url = `${this.baseUrl}/${id}`;
		return this.http.get<ApiResponse<Demanda>>(url).pipe(map((response) => response.data));
	}

	/**
	 * Obtém a lista de todas as demandas, com a possibilidade de aceitar filtros.
	 * @returns Observable de um array de Demandas.
	 */
	getFilteredData(filtros: any): Observable<Demanda[]> {
		const url = `${this.baseUrl}/filtrados`;
		let params = adicionarParamsDinamicamente(filtros, new HttpParams());
		return this.http.get<ApiResponse<Demanda[]>>(url, { params }).pipe(map((response) => response.data));
	}

	/**
	 * Cadastra uma nova demanda.
	 * @param demanda O objeto Demanda a ser cadastrado.
	 * @returns Observable da Demanda cadastrada.
	 */
	post(demanda: Demanda): Observable<Demanda> {
		return this.http.post<ApiResponse<Demanda>>(this.baseUrl, demanda).pipe(map((response) => response.data));
	}

	/**
	 * Atualizar dados de uma Demanda.
	 * @param demandaId O ID da Demanda a ser atualizada.
	 * @param demanda O objeto Demanda a ser atualizado.
	 * @returns Observable da Demanda cadastrada.
	 */
	update(demandaId: number, demanda: Demanda): Observable<Demanda> {
		return this.http
			.put<ApiResponse<Demanda>>(`${this.baseUrl}/${demandaId}`, demanda)
			.pipe(map((response) => response.data));
	}

	/**
	 * Deleta uma Demanda específica pelo seu ID.
	 * @param id O ID da Demanda.
	 * @returns Observable de Demanda.
	 */
	delete(demandaId: number): Observable<any> {
		return this.http.delete(`${this.baseUrl}/${demandaId}`);
	}
}
