import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from './auth.service';

export const authGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): boolean | UrlTree => {
	const authService = inject(AuthService);
	const router = inject(Router);
	const session = authService.getSession();

	// Verifica se existe uma sessão ativa
	const isLoggedIn = session !== null;
	const userRole = session?.role || '';

	// Direcionamento baseado na role e na tentativa de acesso
	if (isLoggedIn) {
		const path = route.routeConfig?.path;

		// Acesso restrito para 'empresa' - Tentar acessar admin sendo Empresa.
		if (userRole === 'empresa' && path?.startsWith('admin')) {
			return router.createUrlTree(['/empresas/demandas']);
		}

		// Acesso restrito para 'admin' - Tentar acessar Empresas sendo Admin.
		if (userRole === 'admin' && path?.startsWith('empresas')) {
			return router.createUrlTree(['/admin/area-conhecimento']);
		}
	}

	// Verifica se o usuário está tentando acessar a rota de login
	if (route.routeConfig?.path === 'login') {
		if (isLoggedIn) {
			// Redirecione com base na role após o login
			return userRole === 'empresa'
				? router.createUrlTree(['/empresas/demandas'])
				: router.createUrlTree(['/admin/area-conhecimento']);
		} else {
			// Usuários não logados podem acessar as rotas de login
			return true;
		}
	}

	// Para outras rotas, verifica se o usuário está logado
	if (!isLoggedIn) {
		// Redireciona usuários não logados para a página inicial da aplicação.
		return router.createUrlTree(['/']);
	}

	// Usuários logados podem acessar outras rotas
	return true;
};
