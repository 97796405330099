import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Aluno } from 'src/app/models/aluno';
import { AlunosService } from 'src/app/services/alunos.service';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
	selector: 'app-multiple-select-alunos',
	templateUrl: './multiple-select-alunos.component.html',
	styleUrls: ['./multiple-select-alunos.component.css'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => MultipleSelectAlunosComponent),
			multi: true,
		},
	],
})
export class MultipleSelectAlunosComponent implements OnInit, ControlValueAccessor {
	alunos: Aluno[] = []; // Substitua any pelo tipo apropriado
	alunosSelecionados: Aluno[] = []; // Substitua any pelo tipo apropriado
	dropdownSettings: IDropdownSettings = {};
	@Input() empresaId: number = 0;

	constructor(private alunosService: AlunosService) {}

	ngOnInit(): void {
		this.dropdownSettings = {
			singleSelection: false,
			idField: 'id',
			textField: 'nome',
			selectAllText: 'Marcar Todos',
			unSelectAllText: 'Desmarcar Todos',
			searchPlaceholderText: 'Filtrar',
			allowSearchFilter: true,
			itemsShowLimit: 5,
			noDataAvailablePlaceholderText: 'A empresa não possui alunos cadastrados',
		};
		this.carregarAlunos();
	}

	public carregarAlunos(): void {
		this.alunosService.getAlunosByEmpresa(this.empresaId).subscribe((alunos) => {
			this.alunos = alunos;
		});
	}

	// Método do ControlAcessValue

	// Função para chamar quando o valor do controle muda
	onChange = (alunosSelecionados: any[]) => {};

	// Função para chamar quando o controle é tocado
	onTouched = () => {};

	// Chamado para escrever o valor pelo modelo de formulário fora para dentro do view
	writeValue(alunosSelecionados: any[]): void {
		this.alunosSelecionados = alunosSelecionados;
	}

	// Registra uma função de callback a ser chamada quando o valor muda
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	// Registra uma função de callback a ser chamada quando o controle foi tocado
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		return;
	}

	// Métodos Select
	onItemSelect(item: any): void {
		// Adiciona o item selecionado ao array de alunosSelecionados se ainda não estiver incluído
		if (!this.alunosSelecionados.some((aluno) => aluno.id === item.id)) {
			this.alunosSelecionados.push(item);
		}
		this.onChange(this.alunosSelecionados);
		this.onTouched();
	}

	onItemDeSelect(item: any): void {
		// Remove o item desselecionado do array de alunosSelecionados
		this.alunosSelecionados = this.alunosSelecionados.filter((aluno) => aluno.id !== item.id);
		this.onChange(this.alunosSelecionados);
		this.onTouched();
	}

	onSelectAll(items: any): void {
		// Atualiza o array de alunosSelecionados com todos os itens disponíveis
		this.alunosSelecionados = items;
		this.onChange(this.alunosSelecionados);
		this.onTouched();
	}

	onDeselectAll(items: any): void {
		// Limpa o array de alunosSelecionados já que nenhum item está selecionado
		this.alunosSelecionados = [];
		this.onChange(this.alunosSelecionados);
		this.onTouched();
	}
}
