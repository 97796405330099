<!-- Conteúdo do collapse -->
<div class="collapse" id="buscaAvancadaCollapse">
	<div class="card card-body">
		<!-- Grid com os campos de busca avançada -->
		<form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
			<div class="row">
				<div *ngFor="let field of filterConfig.fields" class="{{ field.fieldClass }}">
					<div class="mb-3">
						<!-- Label do campo -->
						<label *ngIf="field.fieldType !== 'date'" for="{{ field.fieldName }}">{{ field.displayName }}</label>

						<!-- Switch para renderizar o tipo de campo correto -->
						<ng-container [ngSwitch]="field.fieldType">
							<!-- Campo de texto -->
							<input
								*ngSwitchCase="'text'"
								type="text"
								class="form-control"
								id="{{ field.fieldName }}"
								[formControlName]="field.fieldName"
								[placeholder]="field.placeHolder || 'Digite...'"
							/>

							<!-- Campo de seleção -->
							<select
								*ngSwitchCase="'select'"
								class="form-select"
								id="{{ field.fieldName }}"
								[formControlName]="field.fieldName"
							>
								<option *ngFor="let option of field.options" [value]="option.value">
									{{ option.label }}
								</option>
							</select>

							<!-- Campo numérico -->
							<input
								*ngSwitchCase="'number'"
								type="number"
								class="form-control"
								min=""
								id="{{ field.fieldName }}"
								[formControlName]="field.fieldName"
								[placeholder]="field.placeHolder || 'Digite...'"
							/>

							<!-- Campo de data inicial -->
							<ng-container *ngIf="field.fieldType === 'date' && field.fieldName === 'data_inicial'">
								<mat-form-field appearance="outline">
									<mat-label>Data de Início</mat-label>
									<input
										matInput
										[matDatepicker]="picker1"
										[formControlName]="field.fieldName"
										[placeholder]="field.placeHolder || 'Data Inicial'"
										maxlength="10"
									/>
									<mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
									<mat-datepicker #picker1></mat-datepicker>
								</mat-form-field>
							</ng-container>

							<!-- Campo de data final -->
							<ng-container *ngIf="field.fieldType === 'date' && field.fieldName === 'data_final'">
								<mat-form-field appearance="outline">
									<mat-label>Data de Fim</mat-label>
									<input
										matInput
										[matDatepicker]="picker2"
										[formControlName]="field.fieldName"
										[placeholder]="field.placeHolder || 'Data Final'"
										maxlength="10"
									/>
									<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
									<mat-datepicker #picker2></mat-datepicker>
								</mat-form-field>
							</ng-container>
						</ng-container>
					</div>
				</div>
			</div>

			<!-- Botões de ação -->
			<div class="form-actions text-right">
				<button type="submit" class="br-button primary mr-2">Buscar</button>
				<button type="reset" class="br-button secondary">Limpar</button>
			</div>
		</form>
	</div>
</div>
