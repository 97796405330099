import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterConfig } from 'src/app/models/filter';

/**
 * Componente de Busca Avançada que permite a construção de formulários de filtro dinâmicos
 * baseados na configuração fornecida através da entrada `filterConfig`.
 *
 * Este componente é capaz de gerar campos de formulário dinâmicos e emitir os dados filtrados
 * de volta ao componente pai quando uma busca é realizada.
 */
@Component({
	selector: 'app-busca-avancada',
	templateUrl: './busca-avancada.component.html',
	styleUrls: ['./busca-avancada.component.css'],
	encapsulation: ViewEncapsulation.None,
})
export class BuscaAvancadaComponent implements OnInit {
	/**
	 * Configuração dos campos de filtro que serão gerados dinamicamente no formulário.
	 * Deve ser fornecida pelo componente pai.
	 */
	@Input() filterConfig!: FilterConfig;

	/**
	 * Evento emitido quando o usuário submete o formulário de busca, passando os critérios de filtro.
	 */
	@Output() onSearch: EventEmitter<any> = new EventEmitter<any>();

	/**
	 * Grupo de controles do formulário reativo utilizado para capturar os valores dos campos de filtro.
	 */
	filterForm!: FormGroup;

	constructor(private fb: FormBuilder) {}

	/**
	 * Inicializa o componente, criando o formulário de busca avançada com base na `filterConfig` fornecida.
	 */
	ngOnInit(): void {
		this.initForm();
	}

	/**
	 * Inicializa o formulário reativo, criando controles para cada campo de filtro especificado na configuração.
	 */
	private initForm(): void {
		const group: { [key: string]: any } = {};

		// Cria um controle para cada campo de filtro na configuração.
		this.filterConfig.fields.forEach((field) => {
			group[field.fieldName] = [''];
		});

		this.filterForm = this.fb.group(group);
	}

	/**
	 * Manipula a submissão do formulário de busca, emitindo o evento `onSearch` com os valores dos filtros aplicados.
	 */
	onSubmit(): void {
		this.onSearch.emit(this.filterForm.value);
	}
}
