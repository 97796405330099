<div
  class="br-select"
  [attr.multiple]="multiple ? '' : null"
  [attr.disabled]="disabled ? '' : null"
>
  <div class="br-input">
    <label for="{{ id }}">{{ label }}</label>
    <input id="{{ id }}" type="text" placeholder="Selecione o item" class="" />
    <button
      class="br-button"
      type="button"
      aria-label="Exibir lista"
      tabindex="-1"
      data-trigger="data-trigger"
    >
      <i class="fas fa-angle-up" aria-hidden="true"></i>
    </button>
  </div>
  <div class="br-list" tabindex="0">
    <div
      *ngIf="multiple"
      class="br-item highlighted"
      data-all="data-all"
      tabindex="-1"
    >
      <div class="br-checkbox">
        <input id="{{ id + '-all' }}" name="cbsm" type="checkbox" />
        <label for="{{ id + '-all' }}">Selecionar Todos</label>
      </div>
    </div>
    <div
      class="br-item"
      *ngFor="let option of data; let i = index"
      tabindex="-1"
      (click)="setSelected(); getSelected()"
    >
      <div [class]="multiple ? 'br-checkbox' : 'br-radio'">
        <input
          id="{{ id + i }}"
          [attr.type]="multiple ? 'checkbox' : 'radio'"
          name="regioes"
          value="{{ option }}"
        />
        <label for="{{ id + i }}">{{ option }}</label>
      </div>
    </div>
  </div>
</div>
<p *ngIf="selected">{{ selected }}</p>
