import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrHeaderComponent } from './components/header/header.component';
import { BrMenuComponent } from './components/menu/menu.component';
import { HomePageComponent } from './pages/home/home.component';
import { BrSelectComponent } from './components/select/select.component';
import { LoginComponent } from './pages/login/login.component';
import { CadastroEmpresaComponent } from './pages/cadastro-empresa/cadastro-empresa.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { LoaderComponent } from './components/loader/loader.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SharedModule } from './shared/shared.module';
import { LoginFormComponent } from './pages/login/login-form/login-form.component';

@NgModule({
  declarations: [
    AppComponent,
    BrHeaderComponent,
    BrMenuComponent,
    HomePageComponent,
    BrSelectComponent,
    BrSelectComponent,
    LoginComponent,
    CadastroEmpresaComponent,
    LoaderComponent,
    PageNotFoundComponent,
    LoginFormComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgMultiSelectDropDownModule,
    BrowserAnimationsModule,
    SharedModule
  ],
  providers: [provideNgxMask(), {provide: LOCALE_ID, useValue: 'pt-BR'}],
  bootstrap: [AppComponent],
})
export class AppModule {}
