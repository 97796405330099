<div class="container px-4 py-5" id="featured-3">
	<div class="d-flex justify-content-between align-items-center pb-2 border-bottom">
		<h2 class="no-margin-bottom">Demandas</h2>
		<a
			class="btn d-flex align-items-center busca-avancada"
			data-bs-toggle="collapse"
			href="#buscaAvancadaCollapse"
			role="button"
			aria-expanded="false"
			aria-controls="buscaAvancadaCollapse"
		>
			<span class="d-flex align-items-center">Busca Avançada <i class="fas fa-arrow-down ms-2"></i></span>
		</a>
	</div>
	<app-busca-avancada [filterConfig]="filterConfig" (onSearch)="handleSearch($event)"></app-busca-avancada>
</div>

<div class="container">
	<app-table
		[columns]="columns"
		[data]="demandasData"
		[botaoCadastrarTable]="botaoCadastrarTable"
		[botaoExportarTable]="botaoExportarTable"
		[isLoadingResults]="isLoadingResults"
		[updateUrl]="updateUrl"
		[service]="demandasService"
		(eventoExportar)="exportarRelatorio()"
	></app-table>
</div>
