<section class="vh-100">
	<div class="container-fluid h-custom position-relative">
		<button class="br-button secondary position-absolute btn-back" routerLink="/">
			<i class="fas fa-arrow-left"></i> Voltar
		</button>
		<div class="row d-flex justify-content-center align-items-center h-100">
			<div class="col-md-9 col-lg-6 col-xl-5">
				<img
					src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
					class="img-fluid"
					alt="Sample image"
				/>
			</div>
			<div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
				<mat-tab-group (selectedIndexChange)="onTabChanged($event)">
					<mat-tab label="Empresa Login">
						<app-login-form
							role="empresa"
							[inputLabel]="'CNPJ'"
							[inputMask]="'00.000.000/0000-00'"
							(loginEvent)="handleLogin($event)"
						></app-login-form>
					</mat-tab>
					<mat-tab label="Admin Login">
						<app-login-form
							role="admin"
							[inputLabel]="'CPF'"
							[inputMask]="'000.000.000-00'"
							(loginEvent)="handleLogin($event)"
						></app-login-form>
					</mat-tab>
				</mat-tab-group>
			</div>
		</div>
	</div>
</section>
