<footer class="br-footer">
	<div class="container-lg">
		<div class="logo"><img src="/assets/govbr.png" alt="Imagem" /></div>
		<div class="d-none d-sm-block">
			<div class="row align-items-end justify-content-between py-5">
				<div class="col">
					<div class="social-network">
						<div class="social-network-title">Redes Sociais</div>
						<div class="d-flex">
							<a
								class="br-button circle"
								href="https://www.facebook.com/MDICoficial"
								aria-label="Compartilhar por Facebook"
								target="_blank"
								><i class="fab fa-facebook-f" aria-hidden="true"></i></a
							><a
								class="br-button circle"
								href="https://www.twitter.com/MDICoficial"
								aria-label="Compartilhar por Twitter"
								target="_blank"
								><i class="fab fa-twitter" aria-hidden="true"></i></a
							><a
								class="br-button circle"
								href="https://www.youtube.com/@MDICoficial"
								aria-label="Compartilhar por Youtube"
								target="_blank"
								><i class="fab fa-youtube" aria-hidden="true"></i></a
							><a
								class="br-button circle"
								href="https://www.instagram.com/mdicoficial"
								aria-label="Compartilhar por Instagram"
								target="_blank"
								><i class="fab fa-instagram" aria-hidden="true"></i
							></a>
						</div>
					</div>
				</div>
				<div class="col assigns text-right">
					<a target="_blank" href="https://www.gov.br/acessoainformacao/pt-br"><i class="fa fa-info-circle" style="font-size: 32px; margin-right: 10px;" aria-hidden="true"></i> Acesso à Informação</a>
				</div>
			</div>
		</div>
	</div>
	<span class="br-divider my-3"></span>
	<div class="container-lg">
		<div class="info">
			<div class="text-down-01 text-medium pb-3">
				Todo o conteúdo deste site está publicado sob a licença
				<strong
					><a target="_blank" href="https://creativecommons.org/licenses/by-nd/3.0/deed.pt-br"
						>Creative Commons Atribuição-SemDerivações 3.0 Não Adaptada.</a
					></strong
				>
			</div>
		</div>
	</div>
</footer>
