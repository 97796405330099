import { BotaoTable } from 'src/app/models/botao-cadastrar-table';
import { Cursos } from 'src/app/models/cursos';
import { FilterConfig } from 'src/app/models/filter';
import { TableColumn } from 'src/app/models/table-column';
import { Constants } from 'src/app/app.constants';

/**
 * Define as colunas da tabela de cursos, incluindo ID, nome, formato, escolaridade, carga horária,
 * UF e ações disponíveis.
 */
export const demandasEmpresasTableColumns: TableColumn[] = [
	{ columnDef: 'id', header: 'Nº Demanda', cell: (element) => `${element.id}`, sort: true },
	{ columnDef: 'curso_nome', header: 'Curso', cell: (element) => `${element.curso.nome}`, sort: true },
	{
		columnDef: 'numero_vagas',
		header: 'Número de Vagas',
		cell: (element) => `${element.numero_vagas}`,
		sort: true,
	},
	{
		columnDef: 'escola_ofertante',
		header: 'Escola Ofertante',
		cell: (element) => `${element.escola_ofertante}`,
		sort: true,
	},
	{
		columnDef: 'municipio_nome',
		header: 'Município',
		cell: (element) => `${element.municipio.nome}`,
		sort: true,
	},
	{ columnDef: 'municipio_uf', header: 'UF', cell: (element) => `${element.municipio.uf}`, sort: true },
	{
		columnDef: 'alunos_count',
		header: 'Total de Alunos',
		cell: (element) => `${element.alunos_count}`,
		sort: true,
	},
	{ columnDef: 'acoes', header: 'Ações', cell: (element) => '', type: 'actions', sort: false },
];

export const botaoCadastrarEmpresaConfig: BotaoTable = {
	deveExibir: true,
	titulo: 'Cadastrar Demanda',
	routerLink: Constants.Routes.FullPaths.Empresas.Demandas.CADASTRAR,
};

export const botaoExportarConfig: BotaoTable = {
	deveExibir: false,
	titulo: 'Exportar Demandas',
};

// ---------------------------------------------------------------------- \\
// 							Admin Configs								  \\
// ---------------------------------------------------------------------- \\
/**
 * Define as colunas da tabela de cursos, incluindo ID, nome, formato, escolaridade, carga horária,
 * UF e ações disponíveis.
 */
export const demandasAdminTableColumns: TableColumn[] = [
	{ columnDef: 'id', header: 'Nº Demanda', cell: (element) => `${element.id}`, sort: true },
	{ columnDef: 'curso_nome', header: 'Curso', cell: (element) => `${element.curso.nome}`, sort: true },
	{
		columnDef: 'numero_vagas',
		header: 'Número de Vagas',
		cell: (element) => `${element.numero_vagas}`,
		sort: true,
	},
	{
		columnDef: 'empresa',
		header: 'Empresa',
		cell: (element) => `${element.empresa}`,
		sort: true,
	},
	{
		columnDef: 'municipio_nome',
		header: 'Município',
		cell: (element) => `${element.municipio.nome}`,
		sort: true,
	},
	{ columnDef: 'municipio_uf', header: 'UF', cell: (element) => `${element.municipio.uf}`, sort: true },
	{
		columnDef: 'alunos_count',
		header: 'Total de Alunos',
		cell: (element) => `${element.alunos_count}`,
		sort: true,
	},
];

export const botaoCadastrarAdminConfig: BotaoTable = {
	deveExibir: false,
	titulo: 'Cadastrar Demanda',
	routerLink: Constants.Routes.FullPaths.Empresas.Demandas.CADASTRAR,
};

// ---------------------------------------------------------------------- \\
// 							 Configs Comuns								  \\
// ---------------------------------------------------------------------- \\
/**
 * Configurações para o Filtro Avançado
 */
export const demandasFilterConfig: FilterConfig = {
	fields: [
		{
			fieldClass: 'col-lg-3 col-md-6 col-sm-12',
			fieldName: 'uf',
			displayName: 'UF',
			fieldType: 'select',
			options: [
				{ label: 'Selecione...', value: '' },
				// Adicionado Dinamicamente através do método populate.
			],
		},
		{
			fieldClass: 'col-lg-3 col-md-6 col-sm-12',
			fieldName: 'numero_alunos',
			displayName: 'Número de Alunos',
			fieldType: 'number',
			placeHolder: 'Quantidade Mínima',
		},
		{
			fieldClass: 'col-lg-3 col-md-6 col-sm-12 mt-2',
			fieldName: 'data_inicial',
			displayName: 'Data Inicial',
			fieldType: 'date',
			placeHolder: 'DD/MM/YYYY',
		},
		{
			fieldClass: 'col-lg-3 col-md-6 col-sm-12 mt-2',
			fieldName: 'data_final',
			displayName: 'Data Final',
			fieldType: 'date',
			placeHolder: 'DD/MM/YYYY',
		},
	],
};
