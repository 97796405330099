<div class="background-page">
	<div class="modal-visualization">
		<div class="container px-4 py-5" id="featured-3">
			<h2 class="pb-2 border-bottom">{{ title }}</h2>
		</div>

		<div class="container">
			<form [formGroup]="alunoForm" (ngSubmit)="onSubmit()">
				<div class="row mb-4">
					<div class="col-12 col-md-6 col-lg-6">
						<div class="form-outline">
							<label class="form-label" for="nome">Nome</label>
							<div class="input-group">
								<span class="input-group-text"><i class="fa fa-user"></i></span>
								<input
									type="text"
									id="nome"
									formControlName="nome"
									class="form-control"
									[ngClass]="{
										'is-invalid': alunoForm.get('nome')?.touched && alunoForm.get('nome')?.invalid
									}"
								/>
								<div
									*ngIf="alunoForm.get('nome')?.touched && alunoForm.get('nome')?.invalid"
									class="invalid-feedback"
								>
									Campo obrigatório.
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-4 col-lg-4">
						<div class="form-outline">
							<label class="form-label" for="cpf">CPF</label>
							<div class="input-group">
								<span class="input-group-text"><i class="fa fa-id-card"></i></span>
								<input
									type="text"
									id="cpf"
									formControlName="cpf"
									class="form-control"
									mask="000.000.000-00"
									[ngClass]="{
										'is-invalid': alunoForm.get('cpf')?.touched && alunoForm.get('cpf')?.invalid
									}"
								/>
								<div
									*ngIf="alunoForm.get('cpf')?.touched && alunoForm.get('cpf')?.invalid"
									class="invalid-feedback"
								>
									Campo obrigatório.
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 col-md-2">
						<div class="form-outline">
							<div class="input-group">
								<label class="form-label" for="email">Funcionário da Empresa?</label>
								<div class="br-checkbox mt-2">
									<input
										id="funcionario_empresa"
										formControlName="funcionario_empresa"
										name="funcionario_empresa"
										type="checkbox"
									/>
									<label for="funcionario_empresa">Sim</label>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row mb-4">
					<div class="col-12 col-md-6 col-lg-4">
						<div class="form-outline">
							<label class="form-label" for="email">Email</label>
							<div class="input-group">
								<span class="input-group-text"><i class="fa fa-envelope"></i></span>
								<input
									type="email"
									id="email"
									formControlName="email"
									class="form-control"
									[ngClass]="{
										'is-invalid': alunoForm.get('email')?.touched && alunoForm.get('email')?.invalid
									}"
								/>
								<div
									*ngIf="alunoForm.get('email')?.errors?.['required'] && alunoForm.get('email')?.touched"
									class="invalid-feedback"
								>
									Email é obrigatório.
								</div>
								<div
									*ngIf="alunoForm.get('email')?.errors?.['email'] && alunoForm.get('email')?.touched"
									class="invalid-feedback"
								>
									Por favor, insira um endereço de email válido.
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 col-md-6 col-lg-3">
						<div class="form-outline">
							<label class="form-label" for="telefone">Telefone</label>
							<div class="input-group">
								<span class="input-group-text"><i class="fa fa-phone"></i></span>
								<input
									type="text"
									id="telefone"
									formControlName="telefone"
									class="form-control"
									mask="(00) 00000-0000"
									[ngClass]="{
										'is-invalid':
											alunoForm.get('telefone')?.touched && alunoForm.get('telefone')?.invalid
									}"
								/>
								<div
									*ngIf="alunoForm.get('telefone')?.touched && alunoForm.get('telefone')?.invalid"
									class="invalid-feedback"
								>
									Campo obrigatório.
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 col-md-6 col-lg-3">
						<div class="form-outline">
							<label class="form-label" for="telefone">Data de Nascimento</label>
							<div class="input-group">
								<span class="input-group-text"><i class="fa fa-calendar"></i></span>
								<input
									type="text"
									id="data_nascimento"
									formControlName="data_nascimento"
									class="form-control"
									mask="00/00/0000"
									placeholder="00/00/0000"
									autocomplete="off"
									[ngClass]="{
										'is-invalid':
											alunoForm.get('data_nascimento')?.touched &&
											alunoForm.get('data_nascimento')?.invalid
									}"
								/>
								<div
									*ngIf="
										alunoForm.get('data_nascimento')?.touched &&
										alunoForm.get('data_nascimento')?.invalid
									"
									class="invalid-feedback"
								>
									Campo obrigatório.
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 col-md-6 col-lg-2">
						<div class="form-outline">
							<label class="form-label" for="cep">CEP</label>
							<div class="input-group">
								<span class="input-group-text"><i class="fa fa-envelope"></i></span>
								<input
									type="text"
									id="cep"
									formControlName="cep"
									class="form-control"
									mask="00000-000"
									[ngClass]="{
										'is-invalid': alunoForm.get('cep')?.touched && alunoForm.get('cep')?.invalid
									}"
								/>
								<div
									*ngIf="alunoForm.get('cep')?.touched && alunoForm.get('cep')?.invalid"
									class="invalid-feedback"
								>
									Campo obrigatório.
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row mb-4">
					<div class="col">
						<div class="form-outline">
							<label class="form-label" for="endereco">Endereço</label>
							<div class="input-group">
								<span class="input-group-text"><i class="fa fa-location-arrow"></i></span>
								<input
									type="text"
									id="endereco"
									formControlName="endereco"
									class="form-control"
									[ngClass]="{
										'is-invalid':
											alunoForm.get('endereco')?.touched && alunoForm.get('endereco')?.invalid
									}"
								/>
								<div
									*ngIf="alunoForm.get('endereco')?.touched && alunoForm.get('endereco')?.invalid"
									class="invalid-feedback"
								>
									Campo obrigatório.
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Submit button -->
				<button class="br-button primary mr-3 mt-3" type="submit" [disabled]="alunoForm.invalid">
					{{ buttonSubmit }}
				</button>

				<!-- Se não estiver em um dialog/modal, usa routerLink para navegar -->
				<button
					*ngIf="!inDialog"
					class="br-button secondary mr-3 mt-3"
					type="button"
					routerLink="/empresas/alunos"
				>
					Voltar
				</button>

				<!-- Se estiver em um dialog/modal, chama closeDialog para fechar o modal -->
				<button *ngIf="inDialog" class="br-button secondary mr-3 mt-3" type="button" (click)="closeDialog()">
					Fechar
				</button>
			</form>
		</div>
	</div>
</div>
