import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Aluno } from 'src/app/models/aluno';
import { AlunosService } from 'src/app/services/alunos.service';
import { LoaderService } from 'src/app/services/loader.service';
import { AlertService } from 'src/app/services/alert.service';
import { formatarDataBackEnd, formatarDataFrontEnd } from 'src/app/utils/utils';
import { AuthService } from 'src/app/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-cadastrar-aluno',
	templateUrl: './cadastrar-aluno.component.html',
	styleUrls: ['./cadastrar-aluno.component.css'],
})
export class CadastrarAlunoComponent implements OnInit {
	alunoForm: FormGroup;
	isUpdateMode: boolean = false;
	alunoId?: number;
	title: string = 'Cadastrar Aluno';
	buttonSubmit: string = 'Criar Aluno';
	inDialog: boolean = false;

	/**
	 * Constrói a instância do componente de cadastro ou atualização de aluno.
	 *
	 * @param fb Utilizado para a construção de formulários reativos, facilitando a gestão dos dados do formulário de aluno.
	 * @param alunoService Serviço usado para operações de CRUD (criar, ler, atualizar, deletar) relacionadas aos alunos. Permite obter dados dos alunos e registrar novos alunos no sistema.
	 * @param loaderService Serviço utilizado para controlar a exibição de indicadores de carregamento (loaders) durante as operações assíncronas, melhorando a experiência do usuário.
	 * @param authService Serviço de autenticação que gerencia as informações de autenticação do usuário e controle de acesso.
	 * @param route Utilizado para acessar informações sobre a rota associada a este componente, permitindo, por exemplo, a leitura de parâmetros da URL. Isso é essencial para diferenciar entre a criação e a atualização de um aluno baseando-se na presença ou ausência de um ID na URL.
	 */
	constructor(
		private fb: FormBuilder,
		private alunoService: AlunosService,
		private loaderService: LoaderService,
		private authService: AuthService,
		private route: ActivatedRoute,
		private router: Router,
		@Optional() public dialogRef: MatDialogRef<CadastrarAlunoComponent>,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.alunoForm = this.fb.group({
			nome: ['', Validators.required],
			cpf: ['', Validators.required],
			data_nascimento: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			telefone: ['', Validators.required],
			endereco: ['', Validators.required],
			cep: ['', Validators.required],
			funcionario_empresa: [true, Validators.required],
		});
		if (this.dialogRef?.id) {
			this.inDialog = true;
		}
	}

	ngOnInit(): void {
		this.alunoId = this.route.snapshot.params['id'];
		if (this.alunoId) {
			this.isUpdateMode = true;
			this.buttonSubmit = 'Atualizar Aluno';
			this.title = 'Atualização de Aluno';
			this.carregarDadosAluno(this.alunoId);
		}
	}
	/**
	 * Prepara os dados do formulário de aluno para serem enviados ao backend, aplicando a formatação necessária.
	 * @param aluno Objeto Aluno contendo os dados a serem submetidos.
	 * @param update Flag indicando se a operação é de atualização (true) ou criação (false).
	 * @returns Objeto Aluno formatado conforme a expectativa do backend, com datas ajustadas conforme a operação.
	 */
	montarDadosSubmit(aluno: Aluno, update: boolean = false): Aluno {
		console.log('dados aluno: ', aluno);
		return {
			nome: aluno.nome,
			cpf: aluno.cpf,
			email: aluno.email,
			data_nascimento: update
				? formatarDataFrontEnd(aluno.data_nascimento)
				: formatarDataBackEnd(aluno.data_nascimento),
			funcionario_empresa: aluno.funcionario_empresa,
			endereco: aluno.endereco,
			cep: aluno.cep,
			telefone: aluno.telefone,
			empresa_id: this.authService.getEmpresaId()!,
		};
	}

	/**
	 * Carrega os dados de um aluno específico para edição, utilizando seu ID.
	 * @param id O ID do aluno a ser carregado.
	 */
	carregarDadosAluno(id: number): void {
		this.loaderService.show();
		this.alunoService.getAluno(id).subscribe({
			next: (aluno) => {
				this.loaderService.hide();
				this.alunoForm.patchValue(this.montarDadosSubmit(aluno, true));
			},
			error: (error) => {
				this.loaderService.hide();
				console.error('Erro ao carregar dados do aluno', error);
				AlertService.mostrarAlertaDeErro('Erro ao carregar dados do aluno.');
			},
		});
	}

	/**
	 * Submete o formulário de cadastro ou atualização de aluno, dependendo do modo de operação.
	 * Valida o formulário e chama o método apropriado para criação ou atualização.
	 */
	onSubmit() {
		if (this.alunoForm.valid) {
			this.loaderService.show();
			const dadosBackEnd = this.montarDadosSubmit(this.alunoForm.value);
			if (this.isUpdateMode) {
				this.updateAlunoCadastro(dadosBackEnd);
			} else {
				this.createAlunoCadastro(dadosBackEnd);
			}
		} else {
			AlertService.mostrarAlertaDeErro('Formulário inválido!');
			this.alunoForm.markAllAsTouched();
		}
	}

	/**
	 * Realiza a operação de criação de um novo aluno, enviando os dados ao backend.
	 * @param dadosBackEnd Objeto Aluno contendo os dados formatados para criação.
	 */
	createAlunoCadastro(dadosBackEnd: Aluno) {
		this.alunoService.postAlunoCadastro(dadosBackEnd).subscribe({
			next: (aluno) => {
				this.loaderService.hide();
				AlertService.mostrarAlertaDeSucesso('Aluno criado com sucesso!');
				this.alunoForm.reset();
			},
			error: (error) => {
				this.loaderService.hide();
				console.log('error: ', error);
				AlertService.mostrarAlertaDeErro(error.error.message);
			},
		});
	}

	/**
	 * Realiza a operação de atualização de um aluno existente, enviando os dados modificados ao backend.
	 * @param dadosBackEnd Objeto Aluno contendo os dados formatados para atualização.
	 */
	updateAlunoCadastro(dadosBackEnd: Aluno) {
		const alunoId = this.alunoId!;
		this.alunoService.updateAlunoCadastro(alunoId, dadosBackEnd).subscribe({
			next: (aluno) => {
				this.loaderService.hide();
				AlertService.mostrarAlertaDeSucesso('Aluno atualizado com sucesso!');
			},
			error: (error) => {
				this.loaderService.hide();
				console.log('error: ', error);
				AlertService.mostrarAlertaDeErro(error.error.message);
			},
		});
	}

	/**
	 * Fecha o diálogo/modal atual.
	 * Este método é utilizado quando o componente é carregado dentro de um modal
	 * e permite fechar o modal ao clicar no botão 'Voltar'.
	 */
	closeDialog(): void {
		this.dialogRef.close();
	}

	/**
	 * Navega de volta para a página de listagem de alunos.
	 * Este método é utilizado quando o componente não está carregado dentro de um modal,
	 * permitindo ao usuário voltar para a página de listagem ao clicar no botão 'Voltar'.
	 */
	navigateBack(): void {
		this.router.navigateByUrl('/empresas/alunos');
	}
}
