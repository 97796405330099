import { Component } from '@angular/core'

@Component({
  selector: 'br-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class BrFooterComponent {
  public license: string = 'Todo o conteúdo deste site está publicado sob a licença MIT'

  public logo = {
    url: 'https://cdngovbr-ds.estaleiro.serpro.gov.br/design-system/images/logo-negative.png',
    description: 'Logo de exemplo',
  }
}
