<div class="background-page">
	<div class="modal-visualization">
		<div class="container px-4 py-5" id="featured-3">
			<h2 class="pb-2 border-bottom">{{ title }}</h2>
		</div>

		<div class="container">
			<form [formGroup]="demandaForm" (ngSubmit)="onSubmit()">
				<div class="row mb-4">
					<div class="col-12 col-md-3">
						<div class="form-outline">
							<label class="form-label" for="uf">UF</label>
							<div class="input-group">
								<span class="input-group-text"><i class="fa fa-globe"></i></span>
								<select
									class="form-select"
									formControlName="uf"
									aria-label="Selecione uma UF"
									(change)="onUfChange($event)"
								>
									<option value="" disabled selected>UF</option>
									<option *ngFor="let uf of ufs" [value]="uf">
										{{ uf }}
									</option>
								</select>
							</div>
						</div>
					</div>

					<div class="col-12 col-md-6">
						<div class="form-outline">
							<label class="form-label" for="municipio">Município</label>
							<div class="input-group">
								<span class="input-group-text"><i class="fa fa-map-marker"></i></span>
								<select
									class="form-select"
									formControlName="municipio"
									aria-label="Selecione um Município"
									[ngClass]="{
										'is-invalid':
											demandaForm.get('municipio')?.touched &&
											demandaForm.get('municipio')?.invalid
									}"
								>
									<option value="" disabled selected>Selecione um Município</option>
									<option *ngFor="let municipio of municipios" [value]="municipio.id">
										{{ municipio.nome }}
									</option>
								</select>
								<div
									*ngIf="
										demandaForm.get('municipio')?.touched && demandaForm.get('municipio')?.invalid
									"
									class="invalid-feedback"
								>
									Campo obrigatório.
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-3">
						<div class="form-outline">
							<label class="form-label" for="numero_vagas">Número de Vagas</label>
							<div class="input-group">
								<span class="input-group-text"><i class="fa fa-list-ol"></i></span>
								<input
									type="number"
									id="numero_vagas"
									class="form-control"
									formControlName="numero_vagas"
									min="1"
									[ngClass]="{
										'is-invalid':
											demandaForm.get('numero_vagas')?.touched &&
											demandaForm.get('numero_vagas')?.invalid
									}"
								/>
								<div
									*ngIf="
										demandaForm.get('numero_vagas')?.touched &&
										demandaForm.get('numero_vagas')?.invalid
									"
									class="invalid-feedback"
								>
									Campo obrigatório.
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row mb-4">
					<div class="col-12 col-md-6">
						<div class="form-outline">
							<label class="form-label" for="area_conhecimento">Área do Conhecimento</label>
							<div class="input-group">
								<span class="input-group-text"><i class="fa fa-sitemap"></i></span>
								<select
									class="form-select"
									formControlName="area_conhecimento"
									aria-label="Selecione uma Área do Conhecimento"
									(change)="onAreaConhecimentoChange($event)"
								>
									<option value="" disabled selected>Área do Conhecimento</option>
									<option *ngFor="let ac of areasConhecimento" [value]="ac.id">
										{{ ac.titulo }}
									</option>
								</select>
							</div>
						</div>
					</div>

					<div class="col-12 col-md-6">
						<div class="form-outline">
							<label class="form-label" for="curso">Curso</label>
							<div class="input-group">
								<span class="input-group-text"><i class="fa fa-graduation-cap"></i></span>
								<select
									class="form-select"
									formControlName="curso"
									aria-label="Selecione um Curso"
									[ngClass]="{
										'is-invalid':
											demandaForm.get('curso')?.touched &&
											demandaForm.get('curso')?.invalid
									}"
								>
									<option value="" disabled selected>Selecione um Curso</option>
									<option *ngFor="let curso of cursos" [value]="curso.id">
										{{ curso.nome }}
									</option>
								</select>
								<div
									*ngIf="
										demandaForm.get('curso')?.touched && demandaForm.get('curso')?.invalid
									"
									class="invalid-feedback"
								>
									Campo obrigatório.
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row mb-4">
					<div class="col-12 col-md-8 col-lg-10">
						<div class="form-outline">
							<app-multiple-select-alunos
								[empresaId]="empresaId"
								formControlName="alunos"
							></app-multiple-select-alunos>
						</div>
					</div>

					<div class="col-12 col-md-4 col-lg-2">
						<div class="form-outline">
							<label class="form-label" for="cadastra_aluno"></label>
							<div class="input-group">
								<button class="br-button primary" type="button" (click)="openCadastrarAlunoModal()">Cadastrar Aluno</button>
							</div>
						</div>
					</div>
				</div>

				<div class="row mb-4">
					<div class="col">
						<div class="form-outline">
							<label class="form-label" for="escola_ofertante">Escola Ofertante</label>
							<div class="input-group">
								<span class="input-group-text"><i class="fa fa-building"></i></span>
								<input
									type="text"
									id="escola_ofertante"
									class="form-control"
									formControlName="escola_ofertante"
								/>
							</div>
						</div>
					</div>
				</div>

				<div class="form-outline mb-4">
					<label class="form-label" for="observacoes">Observações</label>
					<div class="input-group">
						<span class="input-group-text"><i class="fa fa-commenting"></i></span>
						<textarea
							id="observacoes"
							class="form-control"
							formControlName="observacoes"
							rows="4"
						></textarea>
					</div>
				</div>

				<!-- Submit button -->
				<button class="br-button primary mr-3 mt-3" type="submit" [disabled]="demandaForm.invalid">
					{{ buttonSubmit }}
				</button>
				<button class="br-button secondary mr-3 mt-3" type="button" routerLink="/empresas/demandas">
					Voltar
				</button>
			</form>
		</div>
	</div>
</div>
