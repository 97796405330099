import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarTopComponent } from '../components/navbar-top/navbar-top.component';
import { BrFooterComponent } from '../components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MultipleSelectAlunosComponent } from '../components/multiple-select-alunos/multiple-select-alunos.component';
import { TableComponent } from '../components/table/table.component';
import { BuscaAvancadaComponent } from '../components/busca-avancada/busca-avancada.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getPortuguesePaginatorIntl } from '../utils/mat-paginator-intl';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { PhonePipe } from '../pipes/phone.pipe';
import { CPFPipe } from '../pipes/cpf.pipe';
import { ConfirmDeleteDialogComponent } from '../components/confirm-delete-dialog/confirm-delete-dialog.component';
import { CadastrarDemandaComponent } from './../modules/empresas/cadastrar-demanda/cadastrar-demanda.component';
import { DemandasComponent } from '../modules/empresas/demandas/demandas.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { SwiperModule } from 'swiper/angular';

@NgModule({
  declarations: [
    NavbarTopComponent,
    BrFooterComponent,
    MultipleSelectAlunosComponent,
    TableComponent,
    BuscaAvancadaComponent,
    PhonePipe,
    CPFPipe,
    ConfirmDeleteDialogComponent,
    CadastrarDemandaComponent,
    DemandasComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgMultiSelectDropDownModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    SwiperModule,
  ],
  exports: [
    NavbarTopComponent,
    BrFooterComponent,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgMultiSelectDropDownModule,
    MultipleSelectAlunosComponent,
    TableComponent,
    BuscaAvancadaComponent,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    ConfirmDeleteDialogComponent,
    CadastrarDemandaComponent,
    DemandasComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    SwiperModule
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: getPortuguesePaginatorIntl() }
  ],
})
export class SharedModule { }
