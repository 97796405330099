import { AfterViewInit, Component } from '@angular/core';
import BRMenu from '@govbr-ds/core/dist/components/menu/menu';

interface MenuItem {
  iconClass: string;
  label: string;
  href?: string;
  children?: MenuItem[];
}

@Component({
  selector: 'br-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class BrMenuComponent implements AfterViewInit {
  instance: any;

  menuItems: MenuItem[] = [
    {
      iconClass: 'fa fa-graduation-cap',
      label: 'Escolas',
      href: '/escolas',
    },
    {
      iconClass: 'fa fa-book',
      label: 'Cursos',
      href: '/home',
    },
    {
      iconClass: 'fa-cubes',
      label: 'Nível 1',
      children: [
        {
          iconClass: 'fa-address-book',
          label: 'Nível 2',
        },
        {
          iconClass: 'fa-heart',
          label: 'Nível 2',
          href: 'javascript:void(0)',
        },
      ],
    },
    {
      iconClass: 'fa fa-bullhorn',
      label: 'Demandas',
      href: '/demandas'
    }
  ];

  /**
   * Inicializa o comportamento do componente somente após todo o HTML ser renderizado na tela
   */

  ngAfterViewInit() {
    this.instance = new BRMenu('.br-menu', document.querySelector('.br-menu'));
  }
}
