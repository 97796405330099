import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
	inputLabel: 'CNPJ' | 'CPF' = 'CNPJ';

	constructor(private authService: AuthService, private router: Router, private loaderService: LoaderService) {}

	ngOnInit(): void {}

	/**
	 * Manipula a mudança das tabs de login, ajustando a interface de acordo.
	 * @param index O índice da tab selecionada.
	 */
	onTabChanged(index: number): void {
		if (index === 0) {
			// Primeira tab (Empresa)
			this.handleRoleChange('empresa');
		} else if (index === 1) {
			// Segunda tab (Admin)
			this.handleRoleChange('admin');
		}
	}

	/**
	 * Ajusta a interface com base na role selecionada na tab de login.
	 * @param selectedRole A role selecionada ('empresa' ou 'admin').
	 */
	handleRoleChange(selectedRole: string): void {
		console.log('selectedRole: ', selectedRole);
		if (this.checkRoleEmpresa(selectedRole)) {
			this.inputLabel = 'CNPJ';
		} else {
			this.inputLabel = 'CPF';
		}
	}

	/**
	 * Verifica se a role selecionada é 'empresa'.
	 * @param selectedRole A role a ser verificada.
	 * @returns Verdadeiro se a role for 'empresa', falso caso contrário.
	 */
	checkRoleEmpresa(selectedRole: string) {
		return selectedRole === 'empresa';
	}

	/**
	 * Realiza o login com os dados fornecidos.
	 * @param loginData Dados do login, incluindo nome de usuário e senha.
	 */
	handleLogin(loginData: any) {
		this.loaderService.show();

		this.authService.login(loginData.userName, loginData.password, this.inputLabel).subscribe({
			next: (response) => {
				console.log('response: ', response);
				this.loaderService.hide();
				this.navigateByRole(response.role);
			},
			error: (error) => {
				console.error('Login failed', error);
				this.loaderService.hide();
				AlertService.mostrarAlertaDeErro('Usuário ou senha incorretos.');
			},
		});
	}

	/**
	 * Navega para a página apropriada com base na role do usuário após o login.
	 * @param role A role do usuário autenticado.
	 */
	private navigateByRole(role: string): void {
		switch (role) {
			case 'admin':
				this.router.navigateByUrl('/admin/area-conhecimento');
				break;
			case 'empresa':
			default:
				this.router.navigateByUrl('/empresas/demandas');
				break;
		}
	}
}
