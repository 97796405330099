<div class="filter-container">
	<div class="button-cadastrar" >
		<button *ngIf="botaoCadastrarTable?.deveExibir" class="br-button primary mr-3" type="button" [routerLink]="botaoCadastrarTable?.routerLink">
			{{ botaoCadastrarTable?.titulo }}
		</button>
		<button *ngIf="botaoExportarTable?.deveExibir" class="br-button success" type="button" (click)="exportarRelatorio()">
			{{ botaoExportarTable?.titulo }}
		</button>
	</div>
	<div class="filter">
		<mat-form-field appearance="fill">
			<mat-label>Filtrar</mat-label>
			<input matInput (keyup)="applyFilter($event)" placeholder="Digite para filtrar..." />
		</mat-form-field>
	</div>
</div>

<div class="mat-elevation-z6 mb-4">
	<table
		mat-table
		[dataSource]="dataSource"
		matSort
		(matSortChange)="announceSortChange($event)"
		class="mat-elevation-z6"
	>
		<ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
			<!-- Header Columns -->
			<ng-container *ngIf="column.sort; else noSortHeader">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.header }}</th>
			</ng-container>
			<ng-template #noSortHeader>
				<th mat-header-cell *matHeaderCellDef>{{ column.header }}</th>
			</ng-template>

			<!-- RoW Columns -->
			<td mat-cell *matCellDef="let element">
				<!-- Renderização condicional baseada no tipo -->
				<ng-container [ngSwitch]="column.type">
					<ng-container *ngSwitchCase="'horas'">{{ element[column.columnDef] }} horas </ng-container>
					<ng-container *ngSwitchCase="'date'"
						>{{ element[column.columnDef] | date : "shortDate" }}
					</ng-container>
					<ng-container *ngSwitchCase="'currency'"
						>{{ element[column.columnDef] | currency : "BRL" : true }}
					</ng-container>
					<ng-container *ngSwitchCase="'telefone'">{{ element[column.columnDef] | phone }} </ng-container>
					<ng-container *ngSwitchCase="'cpf'">{{ element[column.columnDef] | cpf }} </ng-container>
					<ng-container *ngSwitchCase="'actions'">
						<!-- Botões de ação -->
						<button class="br-button circle primary mr-1" (click)="edit(element)">
							<i class="fa fa-pencil"></i>
						</button>
						<button class="br-button circle danger" (click)="delete(element)">
							<i class="fa fa-trash"></i>
						</button>
					</ng-container>
					<ng-container *ngSwitchCase="'detail'">
						<button class="br-button circle primary mr-1" (click)="redirect(element)">
							<i class="fa fa-search"></i>
						</button>
					</ng-container>
					<ng-container *ngSwitchDefault>{{ element[column.columnDef] }}</ng-container>
				</ng-container>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>

	<div class="loading-screen w-100">
		<div *ngIf="isLoadingResults" class="spinner-border mt-3 mb-3" role="status">
			<span class="visually-hidden">Carregando...</span>
		</div>
	</div>

	<!-- Linha exibida quando não há dados -->
	<div class="loading-screen w-100">
		<div *ngIf="!isLoadingResults && !dataSource.data.length" class="mt-3 mb-3" role="status">
			<span>Nenhum dado encontrado.</span>
		</div>
	</div>

	<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
