import { BotaoTable } from './../../../models/botao-cadastrar-table';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Demanda } from 'src/app/models/demanda';
import { TableColumn } from 'src/app/models/table-column';
import { DemandasService } from 'src/app/services/demandas.service';
import {
	demandasEmpresasTableColumns,
	demandasFilterConfig,
	botaoCadastrarEmpresaConfig,
	botaoCadastrarAdminConfig,
	demandasAdminTableColumns,
	botaoExportarConfig,
} from './demandas-list-config';
import { FilterConfig } from 'src/app/models/filter';
import { MunicipiosService } from 'src/app/services/municipios.service';
import { LoaderService } from 'src/app/services/loader.service';
import { convertDateToString } from 'src/app/utils/utils';
import { AuthService } from 'src/app/auth.service';
import { Constants } from 'src/app/app.constants';
import { BuscaAvancadaComponent } from 'src/app/components/busca-avancada/busca-avancada.component';
import { ExportService } from 'src/app/services/export.service';
import { AlertService } from 'src/app/services/alert.service';

/**
 * Componente responsável pela exibição e gerenciamento de demandas.
 * Este componente recupera as demandas do backend, processa e as exibe em uma tabela.
 * Além disso, fornece ações como editar e deletar demandas e um botão para cadastrar novas demandas.
 */
@Component({
	selector: 'app-demandas',
	templateUrl: './demandas.component.html',
	styleUrls: ['./demandas.component.css'],
})
export class DemandasComponent implements OnInit {
	/**
	 * Armazena a referência ao componente de busca avançada.
	 */
	@ViewChild(BuscaAvancadaComponent) buscaAvancadaComponent!: BuscaAvancadaComponent;

	/**
	 * URL para Atualizar Demanda.
	 */
	updateUrl: string = Constants.Routes.FullPaths.Empresas.Demandas.CADASTRAR;

	/**
	 * Armazena os filtros dos cursos.
	 */
	filtros: object = {};

	/**
	 * Flag para controlar a exibição da linha de carregamento.
	 */
	isLoadingResults = true;

	/**
	 * Armazena os dados das demandas recuperadas do serviço.
	 */
	demandasData: any[] = [];

	/**
	 * Define as colunas da tabela de demandas, incluindo o tipo do curso, número de vagas, escola ofertante,
	 * nome e UF do município, total de alunos e ações disponíveis.
	 */
	columns: TableColumn[] = demandasEmpresasTableColumns;

	/**
	 * Configuração para o botão de cadastro de novas demandas.
	 * Inclui se o botão deve ser exibido, o título do botão e o routerLink para a página de cadastro.
	 */
	botaoCadastrarTable: BotaoTable = botaoCadastrarEmpresaConfig;

	/**
	 * Configurações para o botão de exportação de cursos.
	 */
	botaoExportarTable: BotaoTable = botaoExportarConfig;

	/**
	 * Configurações para o Filtro Avançado
	 */
	filterConfig: FilterConfig = demandasFilterConfig;

	constructor(
		public demandasService: DemandasService,
		private municipiosService: MunicipiosService,
		private loaderService: LoaderService,
		private authService: AuthService,
		private exportService: ExportService
	) {}

	/**
	 * Ao inicializar o componente, carrega as demandas do serviço.
	 */
	ngOnInit(): void {
		this.carregarDemandas();
		this.populateFilterUfs();
		this.checkRole();
	}

	/**
	 * Recupera as demandas do serviço e processa os dados para exibição na tabela.
	 * Em caso de erro na recuperação, exibe uma mensagem de erro no console.
	 */
	carregarDemandas(): void {
		this.isLoadingResults = true;
		this.demandasService.getFilteredData(this.filtros).subscribe({
			next: (demandas) => {
				this.demandasData = this.montarDemandasData(demandas);
				this.isLoadingResults = false;
				this.loaderService.hide();
			},
			error: (erro) => {
				console.error('Erro ao carregar demandas', erro);
				this.loaderService.hide();
				this.isLoadingResults = false;
			},
		});
	}

	/**
	 * Processa e mapeia os dados das demandas para o formato esperado pela tabela.
	 * @param demandas Array de objetos de demanda recuperados do backend.
	 * @returns Array de objetos de demanda mapeados para exibição na tabela.
	 */
	montarDemandasData(demandas: Demanda[]) {
		return demandas.map((demanda) => ({
			id: demanda.id,
			curso_nome: demanda.curso?.nome,
			numero_vagas: demanda.numero_vagas,
			escola_ofertante: demanda.escola_ofertante ? demanda.escola_ofertante : 'Não Definida',
			municipio_nome: demanda.municipio?.nome,
			municipio_uf: demanda.municipio?.uf,
			alunos_count: demanda.alunos_count,
			empresa: demanda.empresa?.nome
		}));
	}

	/**
	 * Verifica se a Role do USER logado é ADMIN, caso seja altera a visualização da página.
	 */
	checkRole(): void {
		const userRole = this.authService.getUserRole();
		if (userRole === Constants.Roles.ADMIN) {
			this.columns = demandasAdminTableColumns;
			this.botaoCadastrarTable = botaoCadastrarAdminConfig;
			this.botaoExportarTable.deveExibir = true;
		}
	}

	/**
	 * Inicia o processo de exportação de relatório, solicitando o arquivo do backend e disparando o download no cliente.
	 * Este método faz uma chamada ao serviço `exportService` para obter o relatório de demandas em formato Excel.
	 * Ao receber os dados, cria um Blob com o tipo MIME adequado para planilhas Excel e utiliza uma âncora (`<a>`) para
	 * disparar o download do arquivo. O nome do arquivo baixado será 'demandas.xlsx'.
	 */
	exportarRelatorio(): void {
		const filtros = this.buscaAvancadaComponent.filterForm.value || {};
		this.loaderService.show();
		this.exportService.exportDemandas(filtros).subscribe({
			next: (data) => {
				const blob = new Blob([data], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				});
				const url = window.URL.createObjectURL(blob);
				const anchor = document.createElement('a');
				anchor.href = url;
				anchor.download = 'demandas.xlsx';
				document.body.appendChild(anchor); // necessário para o Firefox
				anchor.click();
				window.URL.revokeObjectURL(url);
				document.body.removeChild(anchor);
				this.loaderService.hide();
			},
			error: (error) => {
				console.error('Erro ao exportar relatório', error);
				this.loaderService.hide();
				AlertService.mostrarAlertaDeErro('Erro ao exportar o relatório de cursos.');
			},
		});
	}

	// -----------------------------------------------------------------------------------------------
	// ---------------------------------- Filtros Sections -------------------------------------------
	// -----------------------------------------------------------------------------------------------
	/**
	 * Preenche as opções de filtro para o campo UF com dados obtidos do serviço de municípios.
	 * Cada UF é adicionada como uma opção de seleção no campo de filtro correspondente.
	 */
	private populateFilterUfs(): void {
		this.municipiosService.getUfs().subscribe((ufs) => {
			const ufOptions = ufs.map((uf) => ({ label: uf, value: uf }));
			const ufField = this.filterConfig.fields.find((field) => field.fieldName === 'uf');
			if (ufField) {
				ufField.options = [{ label: 'Selecione...', value: '' }, ...ufOptions];
			}
		});
	}

	/**
	 * Realiza a busca dos cursos com base nos filtros aplicados.
	 * Este método é chamado quando o usuário submete o formulário de busca avançada.
	 * @param filtros Objeto contendo os valores dos filtros aplicados pelo usuário.
	 */
	handleSearch(filtros: any): void {
		this.loaderService.show();
		this.filtros = this.montarFiltroData(filtros);
		this.carregarDemandas();
	}

	/**
	 * Monta um objeto de filtro a partir de valores fornecidos, convertendo datas para o formato de string 'YYYY-MM-DD'.
	 *
	 * @param filtros Um objeto contendo os valores dos filtros fornecidos pelo usuário.
	 * @returns Um objeto contendo os filtros processados, com as datas convertidas para strings no formato 'YYYY-MM-DD'.
	 *          Caso as datas sejam 'null' ou não fornecidas, elas serão mantidas como 'null' no objeto retornado.
	 */
	montarFiltroData(filtros: any): object {
		return {
			uf: filtros.uf,
			numero_alunos: filtros.numero_alunos,
			data_inicial: convertDateToString(filtros.data_inicial),
			data_final: convertDateToString(filtros.data_final),
		};
	}
}
