import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Empresa } from 'src/app/models/empresa';
import { Municipio } from 'src/app/models/municipio';
import { SetorEconomico } from 'src/app/models/setor-economico';
import { EmpresasService } from 'src/app/services/empresas.service';
import { MunicipiosService } from 'src/app/services/municipios.service';
import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
	selector: 'app-cadastro-empresa',
	templateUrl: './cadastro-empresa.component.html',
	styleUrls: ['./cadastro-empresa.component.css'],
})
export class CadastroEmpresaComponent {
	empresaForm: FormGroup;
	ufs: string[] = [];
	setorEconomico: SetorEconomico[] = [];
	municipios: Municipio[] = [];
	isLoading: boolean = false;
	mostrarSenha: boolean = false;

	/**
	 * Construtor do componente de cadastro de empresa.
	 * @param fb Construtor de formulário para criar um grupo de formulário.
	 * @param empresasService Serviço para obter e registrar empresas.
	 * @param municipiosService Serviço para obter informações sobre UFs e municípios.
	 * @param router Serviço de roteamento para navegação.
	 */
	constructor(
		private fb: FormBuilder,
		private empresasService: EmpresasService,
		private municipiosService: MunicipiosService,
		private router: Router,
		private loaderService: LoaderService
	) {
		this.empresaForm = this.fb.group(
			{
				nome_empresa: ['', Validators.required],
				cnpj: ['', Validators.required],
				email: ['', [Validators.required, Validators.email]],
				setor_economico: ['', Validators.required],
				endereco: ['', Validators.required],
				uf: ['', Validators.required],
				municipio: [{ value: '', disabled: true }, Validators.required],
				cep: ['', Validators.required],
				telefone: ['', Validators.required],
				senha: ['', [Validators.required, Validators.minLength(6)]],
				confirmarSenha: [''],
			},
			{ validator: this.confirmarSenhaValidator }
		);
	}

	/**
	 * Método chamado na inicialização do componente para carregar dados necessários.
	 */
	ngOnInit(): void {
		this.carregarUFs();
		this.carregarSetoresEconomicos();
	}

	/**
	 * Carrega a lista de UFs disponíveis.
	 */
	carregarUFs(): void {
		this.municipiosService.getUfs().subscribe((ufs) => {
			this.ufs = ufs;
		});
	}

	/**
	 * Carrega a lista de setores econômicos disponíveis.
	 */
	carregarSetoresEconomicos(): void {
		this.empresasService.getSetoresEconomicos().subscribe((setorEconomico) => {
			this.setorEconomico = setorEconomico;
		});
	}

	/**
	 * Trata a mudança de seleção no select de UF, carregando os municípios correspondentes.
	 * @param event Evento de mudança contendo o valor da UF selecionada.
	 */
	onUfChange(event: any): void {
		const ufSelecionada = event.target.value;
		if (ufSelecionada) {
			this.municipiosService.getMunicipiosPorUf(ufSelecionada).subscribe({
				next: (municipios) => {
					this.empresaForm.get('municipio')?.enable();
					this.municipios = municipios;
				},
				error: (error) =>
					AlertService.mostrarAlertaDeErro('Algo deu errado! Entre em contato com o Administrador!'),
			});
		} else {
			this.municipios = [];
			this.empresaForm.get('municipio')?.disable();
		}
	}

	/**
	 * Submete o formulário de cadastro de empresa, tratando a resposta do servidor.
	 */
	onSubmit() {
		if (this.empresaForm.valid) {
			this.loaderService.show();
			const dadosBackEnd = this.montarDadosSubmit();
			this.empresasService.post(dadosBackEnd).subscribe({
				next: (empresa) => {
					this.loaderService.hide();
					AlertService.mostrarAlertaDeSucesso('Operação realizada com sucesso!');
					this.router.navigate(['login']);
				},
				error: (error) => {
					this.loaderService.hide();
					console.log('error: ', error);
					AlertService.mostrarAlertaDeErro(error.error.message);
				},
			});
		} else {
			AlertService.mostrarAlertaDeErro('Formulário inválido!');
			this.empresaForm.markAllAsTouched();
		}
	}

	/**
	 * Monta o objeto de dados conforme esperado pelo backend para o cadastro de empresa.
	 * @returns O objeto Empresa com os dados do formulário formatados para o backend.
	 */
	montarDadosSubmit(): Empresa {
		return {
			nome: this.empresaForm.value.nome_empresa,
			cnpj: this.empresaForm.value.cnpj,
			email: this.empresaForm.value.email,
			password: this.empresaForm.value.senha,
			empresas_setor_economico_id: this.empresaForm.value.setor_economico,
			endereco: this.empresaForm.value.endereco,
			uf: this.empresaForm.value.uf,
			municipio_id: this.empresaForm.value.municipio,
			cep: this.empresaForm.value.cep,
			telefone: this.empresaForm.value.telefone,
		};
	}

	/**
	 * Valida se o valor do campo 'senha' e 'confirmarSenha' são iguais dentro de um FormGroup.
	 * Este validador personalizado é usado para garantir que os usuários insiram a mesma senha nos campos de senha e confirmação de senha.
	 *
	 * @param formGroup O FormGroup que contém os campos de senha e confirmarSenha a serem validados.
	 * @returns Um objeto de erro com a propriedade 'senhaNaoConfere' se as senhas não conferem, ou null se as senhas são iguais.
	 */
	confirmarSenhaValidator: Validators = (formGroup: FormGroup) => {
		const senha = formGroup.get('senha')?.value;
		const confirmarSenha = formGroup.get('confirmarSenha')?.value;

		return senha === confirmarSenha ? null : { senhaNaoConfere: true };
	};

	/**
	 * Alterna a visibilidade da senha entre visível e oculta.
	 * Este método é usado para permitir que o usuário visualize a senha digitada em campos de senha, melhorando a usabilidade especialmente ao digitar senhas complexas.
	 */
	toggleSenha(): void {
		this.mostrarSenha = !this.mostrarSenha;
	}
}
