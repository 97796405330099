<form [formGroup]="form" class="mt-4">
	<!-- userName input -->
	<label class="form-label" for="userName">{{ inputLabel }}</label>
	<div class="input-group mb-3">
		<span class="input-group-text"><i class="fa fa-user"></i></span>
		<input
			type="text"
			id="userName"
			formControlName="userName"
			class="form-control form-control-lg"
			placeholder="{{ placeHolder }}"
			mask="{{ inputMask }}"
			[ngClass]="{
				'is-invalid': form.get('userName')?.touched && form.get('userName')?.invalid
			}"
		/>
		<div *ngIf="form.get('userName')?.touched && form.get('userName')?.invalid" class="invalid-feedback">
			Campo obrigatório.
		</div>
	</div>

	<!-- Password input -->
	<label class="form-label" for="email">Password</label>
	<div class="input-group">
		<span class="input-group-text"><i class="fa fa-key"></i></span>
		<input
			type="password"
			id="password"
			formControlName="password"
			class="form-control form-control-lg"
			placeholder="Digite sua senha"
			[ngClass]="{
				'is-invalid': form.get('password')?.touched && form.get('password')?.invalid
			}"
		/>
		<div *ngIf="form.get('password')?.touched && form.get('password')?.invalid" class="invalid-feedback">
			Campo obrigatório.
		</div>
	</div>

	<div class="text-center text-lg-start pt-2 mt-4 mb-4">
		<button class="br-sign-in primary" type="button" (click)="login()" [disabled]="!form.valid">
			<i class="fas fa-user" aria-hidden="true"></i>Entrar
		</button>
		<p class="small fw-bold mt-2 pt-1 mb-0" [ngClass]="{'invisible': role !== 'empresa'}">
			Não tem conta? <a routerLink="/cadastro" class="link-danger">Cadastre-se aqui</a>
		</p>
	</div>
</form>
