<div class="br-message info" role="alert">
  <div class="icon">
    <i class="fas fa-info-circle" aria-hidden="true"> </i>
  </div>
  <div class="content">
    <span class="message-body"> Leia com atenção as informações abaixo </span>
  </div>
  <div class="close">
    <button class="br-button circle small" type="button" aria-label="Fechar">
      <i class="fas fa-times" aria-hidden="true"></i>
    </button>
  </div>
</div>
