// src/app/services/alert.service.ts
import Swal, { SweetAlertOptions } from 'sweetalert2';

export class AlertService {
	static swalWithCustomButtons = Swal.mixin({
		customClass: {
			confirmButton: 'br-button primary',
			cancelButton: 'btn btn-danger',
		},
		buttonsStyling: false,
	});

	static mostrarAlertaDeSucesso(mensagem: string) {
		const opcoes: SweetAlertOptions = {
			title: 'Sucesso!',
			text: mensagem,
			icon: 'success',
			confirmButtonText: 'OK',
		};
		this.swalWithCustomButtons.fire(opcoes);
	}

	static mostrarAlertaDeErro(mensagem: string = 'Contate o Administrador do sistema.') {
		const opcoes: SweetAlertOptions = {
			title: 'Ops!',
			text: mensagem,
			icon: 'error',
			confirmButtonText: 'Fechar',
		};
		this.swalWithCustomButtons.fire(opcoes);
	}
}
