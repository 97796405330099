import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'cpf',
})
export class CPFPipe implements PipeTransform {
	transform(value: string): string {
		// Verifica se o valor é válido
		if (!value) {
			return value;
		}

		// Formata o CPF: 703.837.754-00
    const parte1 = value.slice(0, 3);
    const parte2 = value.slice(3, 6);
    const parte3 = value.slice(6, 9);
    const parte4 = value.slice(9);

    return `${parte1}.${parte2}.${parte3}-${parte4}`;
	}
}
